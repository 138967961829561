import ApiHelper from "@/api/ApiHelper"

export default {
    async getRecordsByTableID(tableId, clist) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        };

        let data = JSON.stringify({
            clist: clist,
            token: localStorage.token,
        });

        let url = "/v1/qb/table/" + tableId + "/records";

        return await ApiHelper.getResult(url, data, headers);
    },

    async getRecordDataByRecordIDWithBody(tableId, rid, body) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        };

        let data = JSON.stringify(body);

        let url = "/v1/qb/table/" + tableId + "/rid/" + rid + "/query";

        return await ApiHelper.getResult(url, data, headers);
    },

    async getRecordDataByRecordID(tableId, rid) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        };

        let data = JSON.stringify({});

        let url = "/v1/qb/table/" + tableId + "/rid/" + rid;

        return await ApiHelper.getResult(url, data, headers);
    },

    async getTableInfo(tableId) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        };

        let data = {};

        let url = "/v1/qb/table/" + tableId + "/info";

        return await ApiHelper.getResult(url, data, headers);
    },

    async pushDataToQB(body) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        };

        let data = JSON.stringify(body);

        let url = "/v1/qb/lifting";

        return await ApiHelper.getResult(url, data, headers);
    },

    async setLigtingToDocument(liftingRid, docRid, body = {token: localStorage.token}) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        };

        let data = JSON.stringify(body);

        let url = "/v1/qb/link/documents/" + docRid + "/liftings/" + liftingRid + "";

        return await ApiHelper.getResult(url, data, headers);
    },
}
 <template>
  <div>
    <div class="modal__header">
      <div class="modal__header-content">
        <div class="modal__header-info">
          <p class="modal__header-name">Edit Report</p>
          <div class="modal__header-input">
            <p class="modal__header-text">Report Name</p>
            <input type="text" v-model="this.name" placeholder="Name" class="title__data-input">
          </div>
          <div class="modal__header-text">
            <p>Last Save</p> <!-- add field for last save from qb! -->
            <p></p>
          </div>
        </div>
        <div class="modal__header-panel">
            <div class="report-item__buttons" v-on:click="() => this.showDeleteConfirmation(reportId)">
              <div class="report-item__delete-button"></div>
              <p class="report-item__text">Delete</p>
            </div>
          <div
            class="modal__close-button"
            v-on:click="
              () => {
                this.$router.push({
                  name: 'LiftingReportsShowView',
                  params: {},
                });
              }
            "
          >
            <div class="close-button__img"></div>
            <span>Cancel</span>
          </div>
          <div class="modal__save-button" v-on:click="() => this.saveReport()">
            <CustomLoader v-if="savingStatus" />
            <div v-else class="save-button__body">
              <div class="save-button__img"></div>
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-panel">
      <div class="list-panel-column">
        <h2 class="list-panel-header">Select Fields</h2>
        <input type="search" class="search" @input="filterList" v-model="searchText" placeholder="Search">
        <div class="first-list custom-scroll">
          <ul>
            <li v-for="(item, index) in firstList" :key="index" @click="selectedItem = item" class="list-item" :class="{ 'selected': selectedItem === item, 'hidden': this.checkVisibleForItem(item) }">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="move-btns-panel">
        <div style="width: 50px;">
          <div>
            <button :disabled="!selectedItem" class="control-button" :class="{ 'control-button__disabled': !selectedItem}" @click="moveToSecondList(selectedItem)">
              <img src="../../../assets/arrow-white-right.svg" alt="">
            </button>
          </div>
          <div>
            <button  class="control-button" :class="{ 'control-button__disabled': !selectedItem2}" @click="moveToFirstList()">
              <img src="../../../assets/arrow-white-left.svg" alt="">
            </button>
          </div>
        </div>
      </div>
      <div class="list-panel-column">
        <h2 class="list-panel-header">Fields in the Report</h2>
        <input type="search" class="search" @input="filterListForSecond" v-model="secondSearchText" placeholder="Search">
        <!-- <div class="second-list custom-scroll">
          <ul>
            <li v-for="(item, index) in secondList" 
            :key="index" 
            @click="selectedItem2 = item" 
            class="list-item" 
            :class="{ 'selected': selectedItem2 === item, 'hidden': this.checkVisibleForItem(item) }">
              {{ item.name }}
            </li>
          </ul>
        </div> -->
        <div class="second-list custom-scroll">
          <div class="stick-container">
            <div
              class="stick"
              ref="stick"
              :style="{
                top: `0px`,
                height: `${(stick.length) * (itemHeight + itemSpacing)}px`,
                backgroundColor: '#2c3e50',
              }"
            >
              <!-- <p>Group {{ index + 1 }}</p> -->
              <!-- <p>Range: {{ group.range[0] + 1 }} - {{ group.range[1] + 1 }}</p> -->

              <!-- <div class="resizer-top" @mousedown="startResize($event, index, 'top')"></div> -->

              <div class="resizer-bottom" @mousedown="startStickResize($event, 'bottom')"></div>

              <!-- <button @click="removeGroup(index)" class="delete-btn">❌</button> -->
            </div>


          </div>
          <div class="second-list-items">
            <ul>
              <li
                v-for="(item, index) in secondList"
                :key="index"
                :class="{
                  'selected': selectedItems.includes(item),
                  'colored': isInSelectedRange(index),
                }"
                

                :style="{ backgroundColor: getItemColor(index) }"
                @click="($event) => {
                  toggleSelection(index);
                  handleSelection(item, index, $event);
                }"
              >
              <!-- :style="{ backgroundColor: getColorForIndex(index) || 'transparent' }"
                @click="handleSelection(item, index, $event)" -->
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="group-container">
            <div
              v-for="(group, index) in groups"
              :key="index"
              class="group"
              :style=getGroupStyles(group)
            >
              <!-- <p>Group {{ index + 1 }}</p> -->
              <!-- <p>Range: {{ group.range[0] + 1 }} - {{ group.range[1] + 1 }}</p> -->

              <input type="color" v-model="group.color" class="color-picker" />

              <div class="resizer-top" @mousedown="startResize($event, index, 'top')"></div>

              <div class="resizer-bottom" @mousedown="startResize($event, index, 'bottom')"></div>

              <button @click="removeGroup(index)" class="delete-btn">❌</button>
            </div>
          </div>
        </div>
      </div>
      <div class="move-btns-panel">
        <div style="width: 50px;">
          <!-- <button :disabled="!selectedItem2" :class="{ 'control-button__disabled': !selectedItem2}" class="control-button" @click="changeOrder(selectedItem2, 'up')">
            <img src="../../../assets/arrow-white-up.svg" alt="">
          </button>
          <button :disabled="!selectedItem2" :class="{ 'control-button__disabled': !selectedItem2}" class="control-button" @click="changeOrder(selectedItem2, 'down')">
            <img src="../../../assets/arrow-white-down.svg" alt="">
          </button> -->

          <button :disabled="!selectedItems.length" @click="moveSelectionUp" class="control-button">
            <img src="../../../assets/arrow-white-up.svg" alt="">
          </button>
          <button :disabled="!selectedItems.length" @click="moveSelectionDown" class="control-button">
            <img src="../../../assets/arrow-white-down.svg" alt="">
          </button>
          <!-- <button :disabled="!selectedItems.length" @click="moveSelectionUp">↑ Up</button> -->
          <!-- <button :disabled="!selectedItems.length" @click="moveSelectionDown">↓ Down</button> -->
        </div>
      </div>
      <!-- <input type="color" v-model="currentColor" @change="applyColorToCurrentRange" class="color-picker"> -->
      
    </div>

    <div class="bottom-controls">
      <div class="color-selection">
        <label>Group color:</label>
        <input type="color" v-model="selectedColor" />
      </div>
      <button @click="addGroup" class="add-btn">➕ Add group</button>
    </div>

    <button v-if="this.sorting.length == 0" class="add-sort-button" v-on:click="() => { this.addSortsItem() }">
      <img src="../../../assets/Add_ring.svg" class="sort-item__create-button_img" alt="">
      <p class="add-sort-text">Add Sorting</p>
    </button>
    <div v-else class="sort-panel">
      <h2 class="sort-panel__header">Sorting</h2>

      <div :class="{ 'first-sort-item': index == 0, 'sort-item': true }" v-for="(item, index) in this.sorting" :key="index">
        <div class="sort-item__operator">
          <span v-if="index > 0">then</span>
        </div>
        <Multiselect
            :options="sort"
            :searchable="true"
            label="name"
            track-by="name"
            v-model="item.sort"
            placeholder="Choose sorting type"
            class="select-value"
        />
        <Multiselect
            :options="this.prepFields"
            :searchable="true"
            :label="'name'"
            :track-by="'name'"
            trackBy="name"
            v-model="item.fid"
            :multiple="true"
            :clear-on-select="false"
            preserve-search="true"
            clearOnSelect="false"
            placeholder="Choose field"
            :preselect-first="true"
            :valueProp="'id'"
            class="select-custom"
        />

        <div class="sort-item__delete-button_img" v-on:click="() => {
            this.removeSortItem(index);
          }"></div>
        <div class="sort-item__add-button_img" v-on:click="() => {
            this.addSortItem();
          }"></div>
      </div>
    </div>

    <button v-if="this.filters.length == 0 || this.filterType == null" class="add-filter-button" v-on:click="() => { this.addFiltersItem() }">
      <img src="../../../assets/Add_ring.svg" class="filter-item__create-button_img" alt="">
      <p class="add-filter-text">Add Filters</p>
    </button>
    <div v-else class="filter-panel">
      <h2 class="filter-panel__header">Filters</h2>
      <div class="filter-panel__type">
        <Multiselect
          :options="this.filterTypes"
          v-model="this.filterType"
          class="select-custom"
          placeholder="Choose type"
        />
      </div>
      <div class="filter-item" v-for="(item, index) in this.filters" :key="index">
        <div class="filter-item__operator">
          <span v-if="this.filterType == 'Any'">or</span>
          <span v-else-if="this.filterType == 'All'">and</span>
        </div>
        <Multiselect
            :options="this.prepFields"
            :searchable="true"
            :label="'name'"
            :track-by="'name'"
            trackBy="name"
            v-model="item.fid"
            :multiple="true"
            :clear-on-select="false"
            preserve-search="true"
            clearOnSelect="false"
            placeholder="Choose field"
            :preselect-first="true"
            :valueProp="'id'"
            class="select-custom"
        />

        <Multiselect
                     :options="this.filterOperators"
                     :searchable="true"
                     :label="'name'"
                     :track-by="'name'"
                     trackBy="name"
                     v-model="item.operator"
                     placeholder=""
                     class="select-value"
        />

        <input type="text" v-model="item.value" class="filter-item__value" placeholder="the value">
        <div class="filter-item__delete-button_img" v-on:click="() => {
            this.removeFilterItem(index);
          }"></div>
        <div class="filter-item__add-button_img" v-on:click="() => {
            this.addFilterItem();
          }"></div>
      </div>
    </div>

    <DeleteConfirmation
        :on-confirm="confirmDelete"
        :on-cancel="cancelDelete"
        :is-report="true"
        v-if="showConfirmation"
    />

    <ShowReportPreviewPopUp
        :url-preview="this.urlPreview"
        :on-cancel="cancelPreview"
        v-if="showPreview"
    />

  </div>
</template>

<script>
import LiftingReportRepository from '@/api/LiftingReport';
import CustomLoader from "@/components/loader/CustomLoader.vue";
import Multiselect from "@vueform/multiselect";
import DeleteConfirmation from "@/components/pop-up/DeleteConfirmation.vue";
import ShowReportPreviewPopUp from "@/components/pop-up/ShowReportPreviewPopUp.vue";

export default {
  data() {
    return {
      name: "",
      table: "",
      clist: [],

      firstList: [
        /* { id: 1, name: 'Елемент 1' },
        { id: 2, name: 'Елемент 2' },
        { id: 3, name: 'Елемент 3' } */
      ],
      secondList: [],
      selectedItem: null,
      selectedItem2: null,

      fields: [],
      prepFields: [],
      showConfirmation: false,
      showPreview: false,
      savingStatus: false,

      searchText: "",
      secondSearchText: "",

      sortingThen: "then",
      filterType: "Any",
      filterTypes: ["Any", "All"],
      filters: [
        //  {
        //   fid: 3,
        //   operator: 'CT',
        //   value: "123",
        // }
      ],
      filterOperators: [
        {
          name: "Contains",
          value: "CT",
        },
        {
          name: "is equal to",
          value: "EX",
        }
      ],
      sorting: [
          //fid: 3
          //sort: DESC
      ],
      sort: [
        {
          name: "sort from low to high by",
          value: "ASC",
        },
        {
          name: "sort from high to low by",
          value: "DESC",
        }
      ],
      test: "",
      urlPreview: "",

      selectedItems: [],
      selectedRanges: [], 
      shiftStartIndex: null, 
      currentColor: "#ffeb3b",


      items: [
        { id: 1, name: "Item 1" },
        { id: 2, name: "Item 2" },
        { id: 3, name: "Item 3" },
        { id: 4, name: "Item 4" },
        { id: 5, name: "Item 5" },
        { id: 6, name: "Item 6" },
        { id: 7, name: "Item 7" },
        { id: 8, name: "Item 8" },
      ],
      groups: [],
      selectedIndices: [],
      itemHeight: 22, 
      itemSpacing: 0, 
      selectedColor: "#ffcc00", 

      stick: [],
      
    };
  },
  mounted() {
    this.init();
  },
  components: {
    ShowReportPreviewPopUp,
    CustomLoader,
    Multiselect,
    DeleteConfirmation,
  },
  props: {
    id: Number,
    open: Boolean, // delete
  },
  methods: {
    handleSelection(item, index, event) {
      if (event.shiftKey && this.shiftStartIndex !== null) {
        this.selectedItems = [];
        const start = Math.min(this.shiftStartIndex, index);
        const end = Math.max(this.shiftStartIndex, index);

        for (let i = start; i <= end; i++) {
          this.selectedItems.push(this.secondList[i]);
        }

        this.selectedRanges.push({ startIndex: start, endIndex: end, color: this.currentColor });
      } else {
        if (this.selectedItems.includes(item)) {
          this.selectedItems = this.selectedItems.filter(i => i !== item);
        } else {
          this.selectedItems = [item];
        }
        this.shiftStartIndex = index;
      }
    },
    isInSelectedRange(index) {
      return this.selectedRanges.some(range => index >= range.startIndex && index <= range.endIndex);
    },
    getColorForIndex(index) {
      const range = this.selectedRanges.find(range => index >= range.startIndex && index <= range.endIndex);
      return range ? range.color : null;
    },
    applyColorToCurrentRange() {
      if (this.selectedRanges.length) {
        this.selectedRanges[this.selectedRanges.length - 1].color = this.currentColor;
      }
    },
    moveSelectionUp() {
      const selectedIndexes = this.selectedItems.map(item => this.secondList.indexOf(item)).sort();
      if (selectedIndexes[0] === 0) return; 

      for (let i = 0; i < selectedIndexes.length; i++) {
        const index = selectedIndexes[i];
        [this.secondList[index], this.secondList[index - 1]] = [this.secondList[index - 1], this.secondList[index]];
      }

      this.recalcStick();
    },
    moveSelectionDown() {
      const selectedIndexes = this.selectedItems.map(item => this.secondList.indexOf(item)).sort().reverse();
      if (selectedIndexes[0] === this.secondList.length - 1) return;

      for (let i = 0; i < selectedIndexes.length; i++) {
        const index = selectedIndexes[i];
        [this.secondList[index], this.secondList[index + 1]] = [this.secondList[index + 1], this.secondList[index]];
      }
      this.recalcStick();
    },
    saveRanges() {
      const formattedRanges = this.selectedRanges.map(range => ({
        start: range.startIndex,
        end: range.endIndex,
        color: range.color
      }));

      LiftingReportRepository.updateReport(this.id, {
        ranges: formattedRanges
      }).then(response => {
        console.log("Ranges saved:", response.data);
      }).catch(error => {
        console.error("Error saving ranges:", error);
      });
    },
    
    async init() {
        /* RecordGenerator.getTableFields("brxisk55z").then((response) => {
         this.fields = response["data"];
       }); */
      let responseReport = await LiftingReportRepository.getReport(this.id);
      let report = responseReport.data["data"];
      this.name = report.name;
      this.table = report.table;
      this.clist = JSON.parse(report.clist);
      let filter = JSON.parse(report.filter);
      let sorting = JSON.parse(report.sorting);
      let groups = JSON.parse(report.groups);
      let stickyColumns = JSON.parse(report.sticky_columns);

      if(filter.type) {
        this.filterType = filter.type;
      }

      if(filter.items.length !== 0) {
        this.filters = filter.items;
      }

      if (sorting && Array.isArray(sorting.items) && sorting.items.length !== 0) {
        this.sorting = sorting.items;
      } else {
        console.warn("sorting порожній масив");
      }

      let response = await LiftingReportRepository.getTableFields();
      this.fields = response["data"]["items"];
      // this.fields.forEach((item) => {
      //   this.firstList.push({
      //     id: item.id,
      //     name: item.label,
      //   })
      // });

      this.fields.forEach((item) => {
        this.firstList.push({
          id: item.name_unique,
          name: item.name ?? item.name_unique,
        })

        this.prepFields.push({
          id: item.name_unique,
          name: item.name ?? item.name_unique,
        })
      });
      
      stickyColumns.forEach((item) => {
        this.stick.push(item)
      });

      groups.forEach((item) => {
        this.groups.push(item)
      });

      this.firstList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })

      this.prepFields.sort((a, b) => {
        return a.name.localeCompare(b.name);
      })

      /* for (let i = 0; i < this.firstList.length; i++) {
        if (this.clist.includes(this.firstList[i].id)) {
            this.secondList.push(this.firstList[i]);
            this.firstList.splice(i, 1);
            i--;
        }
      } */
      this.clist.forEach(id => {
          const index = this.firstList.findIndex(obj => obj.id === id);
          if (index !== -1) {
            this.secondList.push(this.firstList[index]);
            this.firstList.splice(index, 1); // Видалення переміщеного об'єкта з first
          }
      });
    },
    moveToSecondList(item) {
      this.secondList.push(item);
      this.firstList = this.firstList.filter(i => i !== item);
      this.selectedItem = null;
    },
    // moveToFirstList(item) {
    //   this.firstList.push(item);
    //   this.firstList.sort((a, b) => {
    //     return a.name.localeCompare(b.name);
    //   })
    //   this.secondList = this.secondList.filter(i => i !== item);
    //   this.selectedItem2 = null;
    // },
    moveToFirstList() {
      this.firstList.push(...this.selectedItems);
      this.firstList.sort((a, b) => a.name.localeCompare(b.name));
      
      this.secondList = this.secondList.filter(i => !this.selectedItems.includes(i));
      this.selectedItems = [];
    },
    changeOrder(item, direction) {
      const list = this.secondList;
      const index = list.indexOf(item);
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      if (newIndex >= 0 && newIndex < list.length) {
        list.splice(index, 1);
        list.splice(newIndex, 0, item);
      }
    },
    downloadPdf() {
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });

      let filterURL = {
        type: this.filterType,
        items: [],
      }
      this.filters.forEach((item) => {
        if(item.fid) {
          filterURL.items.push(item);
        }
      });

      let sortURL = {
        items: [],
      }
      this.sorting.forEach((item) => {
        if(item.fid) {
          sortURL.items.push(item);
        }
      });

      var url = process.env.VUE_APP_API_DOMAIN + "/v1/report-generator/table/brxisk55z/generate?name=" + this.name + "&clist=" + JSON.stringify(clist) + "&filter=" + JSON.stringify(filterURL) + "&sorting=" + JSON.stringify(sortURL);

      window.open(url, "_blank");
    },

    leave() {
      // this.$router.push({ name: 'individuals-view' });
    },
    async saveReport() {
      if(this.savingStatus) return;
      this.savingStatus = true;
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });

      let filterURL = {
        type: this.filterType,
        items: [],
      }
      this.filters.forEach((item) => {
        if(item.fid) {
          filterURL.items.push(item);
        }
      });

      let sortURL = {
        items: [],
      }
      this.sorting.forEach((item) => {
        if(item.fid) {
          sortURL.items.push(item);
        }
      });

      // await ReportGenerator.updateReport(this.id, this.name, clist, filterURL, sortURL);
      const response = await LiftingReportRepository.updateReport(this.id, this.name, clist, this.stick, this.groups, filterURL, sortURL);
      console.log("Server response:", response);

      // this.$router.go(-1)
      this.emitter.emit("messageNotificationSent", "Saved!");
      this.savingStatus = false;
    },
    filterList() {
      const search = this.searchText.toLowerCase();
      this.firstList.forEach(item => {
        if (item.name.toLowerCase().includes(search)) {
          item.visible = true;
        } else {
          item.visible = false;
        }
      });
    },
    filterListForSecond() {
      const search = this.secondSearchText.toLowerCase();
      this.secondList.forEach(item => {
        if (item.name.toLowerCase().includes(search)) {
          item.visible = true;
        } else {
          item.visible = false;
        }
      });
    },
    checkVisibleForItem(item) {
      if(!("visible" in item)) return false;
      return !item.visible;
    },
    addFiltersItem() {
      this.filters = [];
      this.filterType = null;
      this.filters.push({
          fid: null,
          operator: 'EX',
          value: "",
        });
      this.filterType = 'Any';
    },
    addSortsItem() {
      this.sorting = [{
        fid: null,
        sort: "ASC"
      }];
      this.sortingThen = "then";
    },
    addFilterItem() {
      this.filters.push({
        fid: null,
        operator: 'EX',
        value: "",
      });
    },
    addSortItem() {
      this.sorting.push({
        sort: "ASC",
        fid: null,
      });
    },
    removeFilterItem(index) {
      this.filters.splice(index, 1)
    },
    removeSortItem(index) {
      this.sorting.splice(index, 1)
    },
    async preview() {
      let clist = [];
      this.secondList.forEach((item) => {
        clist.push(item.id);
      });

      let filterURL = {
        type: this.filterType,
        items: [],
      }
      this.filters.forEach((item) => {
        if(item.fid) {
          filterURL.items.push(item);
        }
      });
      let sortURL = {
        items: this.sorting.filter(item => item.fid)
      };
      // let sortURL = {
      //   items: [],
      // }
      // this.sorting.forEach((item) => {
      //   if(item.fid) {
      //     sortURL.items.push(item);
      //   }
      // });

      this.urlPreview = process.env.VUE_APP_API_DOMAIN + "/v1/report-generator/table/brxisk55z/generate?name=" + this.name + "&clist=" + JSON.stringify(clist) + "&filter=" + JSON.stringify(filterURL) + "&sorting=" + JSON.stringify(sortURL) + "&preview=1";
      this.showPreview = true;
    },

    showDeleteConfirmation() {
      this.showConfirmation = true;
    },

    async confirmDelete() {
      await LiftingReportRepository.deleteReport(this.id);
      this.$router.go(-1);
      // this.showConfirmation = false;
    },

    cancelDelete() {
      this.showConfirmation = false;
    },

    cancelPreview() {
      this.showPreview = false;
    },


    toggleSelection(index) {
      if (this.selectedIndices.includes(index)) {
        this.selectedIndices = this.selectedIndices.filter((i) => i !== index);
      } else {
        this.selectedIndices.push(index);
      }
    },

    // addGroup() {
    //   if (this.selectedIndices.length === 0) return;

    //   const sortedSelection = [...this.selectedIndices].sort((a, b) => a - b);
    //   const newGroup = {
    //     range: [sortedSelection[0], sortedSelection[sortedSelection.length - 1]],
    //     color: this.selectedColor,
    //   };

    //   this.groups.push(newGroup);
    //   this.selectedIndices = [];
    // },

    addGroup() {
      if (this.selectedIndices.length === 0) return;

      const sortedSelection = [...this.selectedIndices].sort((a, b) => a - b);
      const selectedIds = this.secondList.slice(sortedSelection[0], sortedSelection[sortedSelection.length - 1] + 1).map(item => item.id);

      const newGroup = {
          range: selectedIds, 
          color: this.selectedColor,
      };

      this.groups.push(newGroup);
      this.selectedIndices = [];
    },

    removeGroup(index) {
      this.groups.splice(index, 1);
    },

    // startResize(event, index, direction) {
    //   let startY = event.clientY;
    //   let startRange = [...this.groups[index].range];
    //   const stepSize = this.itemHeight + this.itemSpacing; 

    //   const onMouseMove = (e) => {
    //     let diff = Math.round((e.clientY - startY) / stepSize);

    //     if (direction === "bottom") {
    //       let newEnd = Math.max(
    //         startRange[0],
    //         Math.min(this.secondList.length - 1, startRange[1] + diff)
    //       );
    //       this.groups[index].range[1] = newEnd;
    //     } else if (direction === "top") {
    //       let newStart = Math.min(
    //         startRange[1],
    //         Math.max(0, startRange[0] + diff)
    //       );
    //       this.groups[index].range[0] = newStart;
    //     }
    //   };

    //   const onMouseUp = () => {
    //     document.removeEventListener("mousemove", onMouseMove);
    //     document.removeEventListener("mouseup", onMouseUp);
    //   };

    //   document.addEventListener("mousemove", onMouseMove);
    //   document.addEventListener("mouseup", onMouseUp);
    // },

    // startResize(event, index, direction) {
    //   let startY = event.clientY;
    //   let startRangeIds = [...this.groups[index].range]; // Збереження id
    //   let startRangeIndices = startRangeIds.map(id => this.secondList.findIndex(item => item.id === id));
    //   const stepSize = this.itemHeight + this.itemSpacing; // Точний крок

    //   const onMouseMove = (e) => {
    //       let diff = Math.round((e.clientY - startY) / stepSize);

    //       if (direction === "bottom") {
    //           let newEndIndex = Math.max(
    //               startRangeIndices[0],
    //               Math.min(this.secondList.length - 1, startRangeIndices[1] + diff)
    //           );
    //           this.groups[index].range = this.secondList.slice(startRangeIndices[0], newEndIndex + 1).map(item => item.id);
    //       } else if (direction === "top") {
    //           let newStartIndex = Math.min(
    //               startRangeIndices[1],
    //               Math.max(0, startRangeIndices[0] + diff)
    //           );
    //           this.groups[index].range = this.secondList.slice(newStartIndex, startRangeIndices[1] + 1).map(item => item.id);
    //       }
    //   };

    //   const onMouseUp = () => {
    //       document.removeEventListener("mousemove", onMouseMove);
    //       document.removeEventListener("mouseup", onMouseUp);
    //   };

    //   document.addEventListener("mousemove", onMouseMove);
    //   document.addEventListener("mouseup", onMouseUp);
    // },

    startResize(event, index, direction) {
      let startY = event.clientY;
      let startRangeIds = [...this.groups[index].range]; // Збереження id
      let startRangeIndices = startRangeIds.map(id => this.secondList.findIndex(item => item.id === id));
      const stepSize = this.itemHeight + this.itemSpacing; // Точний крок
      
      if (startRangeIndices.includes(-1)) return; // Перевірка на валідність індексів

      const onMouseMove = (e) => {
          let diff = Math.round((e.clientY - startY) / stepSize);
          let newStartIndex = startRangeIndices[0];
          let newEndIndex = startRangeIndices[startRangeIndices.length - 1];

          if (direction === "bottom") {
              newEndIndex = Math.min(this.secondList.length - 1, newEndIndex + diff);
          } else if (direction === "top") {
              newStartIndex = Math.max(0, newStartIndex + diff);
          }
          
          if (newStartIndex > newEndIndex) return; // Запобігання порожнім діапазонам
          
          const newIds = this.secondList.slice(newStartIndex, newEndIndex + 1).map(item => item.id);
          
          // Перевіряємо, чи є елементи у вже існуючих групах
          const isOverlap = newIds.some(id => this.groups.some((g, i) => i !== index && g.range.includes(id)));
          if (!isOverlap) {
              this.groups[index].range = newIds;
          }
      };

      const onMouseUp = () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },

    getGroupStyles(group) {
      if (!group.range.length) return {};
      
      const indices = group.range.map(id => this.secondList.findIndex(item => item.id === id)).filter(index => index !== -1);
      if (indices.length === 0) return {};
      
      return {
          top: `${indices[0] * (this.itemHeight + this.itemSpacing)}px`,
          height: `${(indices[indices.length - 1] - indices[0] + 1) * (this.itemHeight + this.itemSpacing)}px`,
          backgroundColor: group.color,
          // minHeight: `${this.itemHeight + this.itemSpacing}px`,
      };
    },

    startStickResize(event, direction) {
      let startY = event.clientY;
      const stepSize = this.itemHeight + this.itemSpacing; 

      const onMouseMove = (e) => {
        let diff = Math.round((e.clientY - startY) / stepSize);

        if (direction === "bottom") {
          this.stick = [];
          for (let i = 0; i < diff; i++) {
            this.stick.push(this.secondList[i].id);
          }
        }
      };

      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },

    recalcStick() {
      const stepSize = this.itemHeight + this.itemSpacing; 
      let diff = Math.round(parseInt(this.$refs.stick.style.height) / stepSize);
      // console.log("this.$refs.stick.style.height ", this.$refs.stick.style.height);
      // console.log("diff ", diff);

      this.stick = [];
      for (let i = 0; i < diff; i++) {
        this.stick.push(this.secondList[i].id);
      }
    },

    getItemColor(index) {
      const group = this.groups.find(
        (g) => index >= g.range[0] && index <= g.range[1]
      );
      return group ? group.color : "transparent";
    },
  }
};
</script>

 <style>

 .select-custom, .select-value {
   font-family: Lato;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   color: #2D3757;
   border: 1px solid #D3DFED !important;
   border-radius: 2px !important;
 }

 .select-custom:hover, .select-value:hover {
   border-radius: 2px;
   border: 1px solid #5D88BE !important;
 }

 .select-value {
   margin: 0 12px !important;
   width: 55% !important;
 }

 .filter-item__value {
  width: 100% !important;
 }

 .select-custom .multiselect-clear-icon, .select-value .multiselect-clear-icon{
   width: 20px;
   height: 20px;
   mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5303 5.53033C15.8232 5.23744 15.8232 4.76256 15.5303 4.46967C15.2374 4.17678 14.7626 4.17678 14.4697 4.46967L10 8.93934L5.53033 4.46967C5.23744 4.17678 4.76256 4.17678 4.46967 4.46967C4.17678 4.76256 4.17678 5.23744 4.46967 5.53033L8.93934 10L4.46967 14.4697C4.17678 14.7626 4.17678 15.2374 4.46967 15.5303C4.76256 15.8232 5.23744 15.8232 5.53033 15.5303L10 11.0607L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L11.0607 10L15.5303 5.53033Z" fill="%232D3757" stroke="%232D3757" stroke-width="0.5"/></svg>');
   background-color: #2D3757;
 }

 .select-custom:hover .multiselect-caret, .select-value:hover .multiselect-caret {
   background-color: #5D88BE;
 }

 .select-custom .multiselect-dropdown .multiselect-options .multiselect-option.is-selected,
 .select-value .multiselect-dropdown .multiselect-options .multiselect-option.is-selected
 {
   background-color: #5D88BE !important;
 }

 .select-custom .multiselect-dropdown .multiselect-options .multiselect-option.is-selected.is-pointed,
 .select-value .multiselect-dropdown .multiselect-options .multiselect-option.is-selected.is-pointed
 {
   background-color: #5D88BE !important;
 }

 .select-custom.is-active,
 .select-value.is-active {
   box-shadow: 0 0 0 3px #d3dfed !important;
 }

 </style>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.container {
  display: flex;
}

.list {
  flex: 1;
  padding: 10px;
}

.selected {
  background-color: lightgrey;
}

.list-panel {
  display: flex;
  justify-items: center;
  margin: auto;
  width: 960px;
  box-sizing: border-box;
  border-radius: 2px;
}
.list-panel-header {
  color: #6C6F7D;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-top: 32px;
}

.list-panel-column {
  width: 100%;
}

.first-list, .second-list {
  display: flex;

  height: 200px;
  overflow-y: scroll;
  padding: 5px 0px;
  background: #fff;
  border: 1px solid #d3dfed;
  width: 100%;
}

.second-list-items {
  flex-grow: 3;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: #fdf9f9;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #2D3757;
  width: 4px;
  height: 31px;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  background-color: #C0C0C0;
}

.move-btns-panel {
  align-items: center;
  padding: 15px;
  display: flex;
}

.modal__header-info {
  display: flex;
  align-items: flex-end;
}

.modal__header-name {
  font-family: Lato;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  margin-right: 30px;
}

.modal__header-text {
  color: #6C6F7D;
  font-weight: 400;
  font-size: 12px;
}

.modal__header-panel {
  display: flex;
}

.modal__header-input {
  margin-right: 10px;
}

.report-item__text {
  color: #2D3757;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin-right: 10px;
}

.report-item__buttons {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  border: 1px solid transparent;
}

.report-item__delete-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Trash.svg) no-repeat center center;
  background-size: 24px 24px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 3px;
  fill: #ff0000;
}

.report-item__download-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/Download_circle_fill.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 5px;
  margin-right: 3px;
}

.report-item__preview-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 32px;
  background: url(@/assets/btn-preview.svg) no-repeat center center;
  background-size: 24px 24px;
  margin-left: 5px;
  margin-right: 3px;
}

.report-item__buttons:hover {
  border-color: #5D88BE;
  background-color: #FFF;
  /* margin: -0.5px 11.5px -0.5px -0.5px; */
  /*width: calc(100% - 1px);*/
  /*height: calc(100% - 1px)*/
}

.modal__close-button {
  border: 1px solid #5D88BE;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 58px;
}

.modal__save-button:hover {
  background-color: #50629b;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.title__data-input {
  border: 1px solid #D3DFED;
  border-radius: 2px;
  min-width: 350px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  padding: 8px 11px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}

.title__data-input:hover {
  border: 1px solid #5D88BE;
}

.title__data-input::selection {
  border: 2px solid #5D88BE;
}

.filter-panel, .sort-panel {
  width: 960px;
  max-width: 100%;
  margin: auto;
}

.filter-panel__header, .sort-panel__header {
  color: #6C6F7D;
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 24px 0;
}

.buttons-panel {
  display: flex;
  width: 960px;
  max-width: 100%;
  justify-content: space-around;
  margin: 20px auto;
}

.list-item {
  text-decoration: none;
  list-style: none;
  display: block;
  padding: 3px 10px;
  cursor: pointer;
  user-select: none;
}

.list-item:hover {
  background-color: #EEEEEE;
}

.control-button {
  box-sizing: border-box;
  background: #2d3757;
  border-radius: 2px;
  padding: 10px 11px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 28px;
}

.control-button:hover {
  background: #3F4D79;
}

.hidden {
  display: none;
}

.search {
  display: block;
  width: 100%;
  padding: 5px 11px;
  margin: 12px 0;
  border: 1px solid #D3DFED;
  border-radius: 2px;
}

.search:hover {
  border: 1px solid #5D88BE;
}

.search::selection {
  border: 2px solid #5D88BE;
}
.first-sort-item {
  margin-left: 35px;
}

.filter-item, .sort-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.filter-item__operator, .sort-item__operator {
  padding: 0 12px;
  color: #6C6F7D;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.filter-panel__type {
  margin-top: 10px;
  margin-bottom: 24px;
  width: 124px;
}

.filter-item__value {
  all: unset;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font: inherit;
  line-height: inherit;
  box-shadow: none;
  width: auto;

  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.filter-item__value:hover {
  border-radius: 2px;
  border: 1px solid #5D88BE;
}

.filter-item__value:focus {
  border: 1px solid #5D88BE;
  box-shadow: 0 0 0 3px #d3dfed;
}

.filter-item__value::placeholder {
  color: #2D3757;
}

.filter-item__add-button_img, .sort-item__add-button_img {
  /*width: 120px;*/
  /*height: 40px;*/
  padding: 20px 22px;
  border-radius: 2px;
  background: url(@/assets/Add_ring.svg) no-repeat center center;
  background-color: #2d3757;
  cursor: pointer;
  margin-left: 10px;
}

.filter-item__add-button_img:hover, .sort-item__add-button_img:hover {
  background-color: #3F4D79;
}

.filter-item__delete-button_img, .sort-item__delete-button_img {
  /*width: 120px;*/
  /*height: 40px;*/
  padding: 20px 22px;
  border-radius: 2px;
  background: url(@/assets/Remove.svg) no-repeat center center;
  background-color: #2d3757;
  margin-left: 10px;
  cursor: pointer;
}

.filter-item__delete-button_img:hover, .sort-item__delete-button_img:hover {
  background-color: #3F4D79;
}

.filter-item__create-button_img, .sort-item__create-button_img {
  margin: auto;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  transition: filter 0.3s ease;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
}

.add-filter-button, .add-sort-button {
  color: #2d3757;
  display: flex;
  background: none;
  border-radius: 2px;
  border: 1px solid #5D88BE;
  margin-left: calc(100% - (960px + ((100% - 960px) / 2)));
  margin-top: 40px;
  width: 140px;
  padding: 10px;
  cursor: pointer;
}

.add-filter-button:hover, .add-sort-button:hover {
  background-color: #F6FAFF;
}

.add-filter-text, .add-sort-text {
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.selected {
  border: 1px solid black;
}
.color-picker {
  /* margin-top: 10px; */
}

</style>


<style scoped>
.container {
  display: flex;
  width: 700px;
  border: 1px solid #ccc;
  position: relative;
}

/* .list {
  width: 200px;
  padding: 10px;
  background: #f9f9f9;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-item {
  padding: 10px;
  height: 40px;
  margin-bottom: 6px; 
  cursor: pointer;
  text-align: center;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.bottom-controls {
  margin-top: 20px;
  text-align: center;
}

.separator {
  width: 5px;
  background: #000;
  cursor: ew-resize;
}

.group-container {
  flex-grow: 1;
  /* padding: 10px; */
  background: #eee;
  position: relative;
}

.stick-container {
  flex-grow: 0.3;
  background: #eee;
  position: relative;
}

.stick {
  box-sizing: border-box;
  position: absolute;
  width: calc(100%);
  border: 1px solid #aaa;
  overflow: hidden;
  padding-top: 2px;
  text-align: center;
  transition: height 0.2s;
}

.group {
  box-sizing: border-box;
  position: absolute;
  left: 5px;
  width: calc(100% - 5px);
  /* border: 1px solid #aaa; */
  overflow: hidden;
  /* padding: 10px; */
  text-align: center;
  transition: height 0.2s;
}

.resizer-top,
.resizer-bottom {
  position: absolute;
  width: 100%;
  height: 5px;
  background: #999;
  cursor: ns-resize;
}

.resizer-top {
  top: 0;
}

.resizer-bottom {
  bottom: 0;
}

.delete-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background: red;
  color: white;
  border: none;
  padding: 2px;
  cursor: pointer;
  font-size: 14px;
}

.add-btn {
  display: block;
  margin: 10px auto;
  padding: 10px;
  background: green;
  color: white;
  border: none;
  cursor: pointer;
}

.color-picker {
  position: absolute;
  top: 0px;
  left: 0px;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 25px;
}

.color-selection {
  margin: 0px 0;
  text-align: center;
}

.color-selection input {
  margin-left: 10px;
  cursor: pointer;
}
</style>
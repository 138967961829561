<template>
  <div class="matrics-wrap">
    <div v-if="chartData" class="chart-container">
      <div style="width: calc(100% - 220px); height: calc(100vh - 110px);" class="canvas-container">
        <canvas ref="myChart"></canvas>
      </div>
      <div class="legend-container">
        <span class="legend-title">Status</span>
        <div
          v-for="dataset in chartData.datasets"
          :key="dataset.label"
          class="legend-item"
        >
          <span
            class="legend-color"
            :style="{ backgroundColor: dataset.backgroundColor }"
          ></span>
          <span class="legend-label">{{ dataset.label }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      <CustomLoader />
    </div>
  </div>
</template>

<script>
import CustomLoader from "@/components/loader/CustomLoader.vue";
import MetricsRepository from "@/api/Metrics";
import { Chart, registerables } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

Chart.register(...registerables, DataLabelsPlugin);

export default {
  components: {
    CustomLoader,
  },

  data() {
    return {
      metricsData: null,
      chartInstance: null,
    };
  },

  mounted() {
    this.getMetrics();
  },

  computed: {
    chartData() {
      if (!this.metricsData) return null;

      const labels = Object.keys(this.metricsData);
      const datasets = [];
      const statuses = new Set();

      labels.forEach((company) => {
        Object.keys(this.metricsData[company]).forEach((status) => {
          statuses.add(status);
        });
      });

      const sortedStatuses = Array.from(statuses).sort();

      sortedStatuses.forEach((status) => {
        const data = labels.map((company) => this.metricsData[company][status]?.count || 0);
        const totalAmounts = labels.map((company) => this.metricsData[company][status]?.total_amount || 0);

        if (data.some((value) => value > 0)) {
          datasets.push({
            label: status,
            data,
            totalAmounts,
            backgroundColor: this.getStatusColor(status),
          });
        }
      });

      const maxCount = labels.reduce((max, company) => {
        const sum = datasets.reduce((sum, dataset) => sum + (dataset.data[labels.indexOf(company)] || 0), 0);
        return Math.max(max, sum);
      }, 0);

      return {
        labels,
        datasets,
        maxCount,
      };
    },

    chartOptions() {
      const fontSize = window.innerWidth >= 1920 ? 18 : 16;

      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true, 
            text: "Number of Fixtures per Status per Owner (with Net Demurrage)",
            position: "top",
            font: {
              size: fontSize + 4,
              weight: "normal",
            },
            color: "#000", 
            padding: {
              top: 10,
              bottom: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const dataset = tooltipItem.dataset;
                const index = tooltipItem.dataIndex;
                const totalAmount = dataset.totalAmounts[index] || 0;
                return `${dataset.label}: $${totalAmount.toLocaleString()}`;
              },
            },
          },
          datalabels: {
            display: (context) => {
              const datasetIndex = context.datasetIndex;
              const totalDatasets = context.chart.data.datasets.length;
              return datasetIndex === 0 || datasetIndex === totalDatasets - 1;
            },

            anchor: (context) => {
              if (context.datasetIndex === 0) return "start";
              return "end";
            },
            align: (context) => {
              if (context.datasetIndex === 0) return "top";
              return "top";
            },

            formatter: (value, context) => {
              const datasets = context.chart.data.datasets;
              const index = context.dataIndex;

              if (context.datasetIndex === datasets.length - 1) {
                const totalSum = datasets.reduce((sum, dataset) => {
                  return sum + (dataset.totalAmounts?.[index] || 0);
                }, 0);
                return `$${totalSum.toLocaleString()}`; 
              }

              const totalCount = datasets.reduce((sum, dataset) => {
                return sum + (dataset.data[index] || 0);
              }, 0);
              return totalCount;
            },
            color: "#000",
            font: { size: fontSize },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: { size: fontSize },
              color: "#000",
            },
            title: {
              display: true,
              text: "Owner",
              font: { size: fontSize + 4 },
              color: "#000",
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            max: this.chartData ? this.chartData.maxCount + 1 : undefined,
            ticks: {
              font: { size: fontSize },
              color: "#000",
              callback: function (value) {
                if (Number.isInteger(value)) {
                  return value;
                }
              },
              stepSize: 1,
            },
            title: {
              display: true,
              text: "Fixture Count",
              font: { size: fontSize },
              color: "#000",
            },
          },
        },
      };
    },
  },

  methods: {
    getMetrics() {
      MetricsRepository.getData()
        .then((result) => {
          this.metricsData = result["data"]["data"];

          this.$nextTick(() => {
            this.createChart();
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },

    getStatusColor(status) {
      const statusColors = {
        "2. TBN": "#F5CBA7",
        "3. Nominated": "#FAD7A0",
        "4. Loading": "#D5CBE0",
        "5. Sailing": "#DEECFC",
        "6. Discharging": "#DEECFC",
        "7. Completed": "#A8D5A2",
      };

      if (statusColors[status]) {
        return statusColors[status];
      }
    },

    createChart() {
      if (!this.chartData) {
        console.error("There is no data for the graph!");
        return;
      }

      if (!this.$refs.myChart) {
        console.error("Canvas element not found!");
        return;
      }

      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const ctx = this.$refs.myChart.getContext("2d");

      this.chartInstance = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: this.chartOptions,
      });
    },
  },

  watch: {
    chartData() {
      if (this.chartData) {
        this.createChart();
      }
    },
  },
};
</script>

<style scoped>
  .matrics-wrap {
    width: 100%;
    padding: 30px 30px 0 30px;
    box-sizing: border-box;
  }

  .chart-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }

  /* .matrics-wrap canvas {
    height: 700px !important;
  } */

  .legend-container {
    display: flex;
    flex-direction: column;
    width: 160px;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }

  .legend-title {
    font-size: 20px;
    display: block;
    text-align: center;
    margin-bottom: 5px;
  }

  .legend-item {
    display: flex;
    align-items: center;
  }

  .legend-item:not(:last-child) {
    margin-bottom: 5px;
  }

  .legend-color {
    width: 25px;
    height: 10px;
    margin-right: 10px;
  }

  .legend-label {
    font-size: 18px;
  }

  @media (min-height: 1500px) {
    .canvas-container {
      height: 70vh !important;
      width: 60vw !important;
      margin-left: 10%;
    }
  }

</style>
<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="modal__title">{{ modalName }}</div>
            </div>
            <div class="modal__close"></div>
            <div
              class="modal__close"
              v-on:click="
                () => {
                  this.$emit('showStatus', false);
                }
              "
            ></div>
          </div>

          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <!-- <template v-for="(tab, index) in mapping" :key="index">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-if="isShowTab(tab)"
                >
                  {{ tab.title }}
                </div>
              </template> -->

              <!-- <div class="switch-bar__item switch-bar__item_active">General</div> -->
              <!-- <div class="switch-bar__item">Freight</div>
                <div class="switch-bar__item">Reminders</div> -->
            </div>
            <div class="switcher-content">
              <div v-if="mappingNotEmpty" class="switcher-content__choose">
                <div
                  class="switcher-content__sub-tab"
                  v-for="(subTab, subTabIndex) in mapping[selectedSwitcher][
                    'body'
                  ]"
                  :key="subTabIndex"
                >
                    <div
                      class="switcher-content__sub-tab-title"
                      v-if="subTab.showTitle"
                    >
                      {{ subTab.title }}
                    </div>
                    <div class="switcher-content__sub-tab-body">
                      <table class="switcher-content__sub-tab-table">
                        <tr
                          v-for="(row, rowIndex) in subTab.body"
                          :key="rowIndex"
                        >
                          <template
                            v-for="(value, valueIndex) in row"
                            :key="valueIndex"
                          >
                            <!-- <td v-if="isShowElement(value)" style="word-wrap: break-word;">{{
                                    this.objAllFields[value.key].fieldType !=
                                    "url"
                                      ? this.objAllFields[value.key].label
                                      : ""
                                  }}</td> -->
                            <td
                              v-if="showField(value)"
                              :colspan="
                                valueIndex == row.length - 1
                                  ? value.hasOwnProperty('colspan')
                                    ? value.colspan
                                    : 100
                                  : 1
                              "
                            >
                              <div v-if="this.getTypeForElement(value) == ''">
                                <div class="switcher-content__title">
                                  {{
                                    this.objAllFields[value.key].fieldType !=
                                    "url"
                                      ? this.objAllFields[value.key].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                      : ""
                                  }}
                                </div>
                                <div
                                  v-if="
                                    this.objAllFields[value.key].mode == 'lookup'
                                  "
                                >
                                  <div
                                    v-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'currency'
                                    "
                                  >
                                    <QCurrencyInput
                                      v-model="value.value"
                                      :options="{ currency: 'USD', precision: 2 }"
                                    />
                                  </div>
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'timestamp'
                                    "
                                    style="max-width: 200px"
                                  >
                                    <DatePicker
                                      :format="(t) => formatDateTimeFunc(t)"
                                      :previewFormat="
                                        (t) => formatDateTimeFunc(t)
                                      "
                                      v-model="value.value"
                                      :enableTimePicker="true"
                                      :value-type="valueType.date2value"
                                    />
                                  </div>
                                  <input
                                    v-else
                                    v-model="value.value"
                                    disabled
                                    type="text"
                                    class="switcher-content__data-input disabled"
                                  />
                                </div>

                                <div
                                  class="report-table__item-text-multiple-choice-wrap"
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'text-multiple-choice' && value.key == 6
                                  "
                                >
                                  <div style="max-width: 250px">
                                    <Multiselect
                                        :disabled="true"
                                        v-model="value.value"
                                        :options="
                                      this.objAllFields[value.key].properties.choices.sort()
                                    "
                                        clearOnSelect="false"
                                        placeholder="Choose"
                                        :close-on-select="false"
                                    ></Multiselect>
                                  </div>
                                </div>

                                <div
                                  style="height: 40px"
                                  :data-row="index"
                                  :data-fid="item"
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'rich-text'
                                  "
                                  v-html="value.value"
                                ></div>

                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].mode == 'formula'
                                  "
                                >
                                  <div
                                    v-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'url'
                                    "
                                  >
                                    <a :href="value.value" class="url-btn">{{
                                      this.objAllFields[value.key].properties
                                        .appearsAs
                                    }}</a>
                                  </div>

                                  <textarea
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'text'
                                    "
                                    style="display: block"
                                    v-model="value.value"
                                    class="
                                      switcher-content__text-data-textarea
                                      disabled
                                    "
                                    disabled
                                  >
                                  </textarea>
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'timestamp'
                                    "
                                    style="max-width: 250px"
                                  >
                                    <DatePicker
                                      :format="(t) => formatDateTimeFunc(t)"
                                      :previewFormat="
                                        (t) => formatDateTimeFunc(t)
                                      "
                                      v-model="value.value"
                                      :enableTimePicker="true"
                                      :value-type="valueType.date2value"
                                      :disabled="true"
                                    />
                                  </div>
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'currency'
                                    "
                                  >
                                    <QCurrencyInput
                                      v-model="value.value"
                                      :options="{ currency: 'USD', precision: 2 }"
                                      :disabled="true"
                                    />
                                  </div>
                                  <input
                                    v-else
                                    v-model="value.value"
                                    disabled
                                    type="text"
                                    class="switcher-content__data-input disabled"
                                  />
                                </div>

                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].mode == 'summary'
                                  "
                                >
                                  <div
                                    v-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'currency'
                                    "
                                  >
                                    <QCurrencyInput
                                      v-model="value.value"
                                      :options="{ currency: 'USD', precision: 2 }"
                                      :disabled="true"
                                    />
                                  </div>
                                  <div
                                    v-else-if="
                                      this.objAllFields[value.key].fieldType ==
                                      'timestamp'
                                    "
                                    style="max-width: 250px"
                                  >
                                    <DatePicker
                                      :format="(t) => formatDateTimeFunc(t)"
                                      :previewFormat="
                                        (t) => formatDateTimeFunc(t)
                                      "
                                      v-model="value.value"
                                      :value-type="valueType.date2value"
                                      :disabled="true"
                                      :hideInputIcon="true"
                                      inputClassName="dp-custom-input"
                                    />
                                  </div>
                                  <input
                                    v-else
                                    v-model="value.value"
                                    disabled
                                    type="text"
                                    class="switcher-content__data-input disabled"
                                  />
                                </div>
                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'checkbox'
                                  "
                                >
                                  <input
                                    v-model="value.value"
                                    type="checkbox"
                                    class="switcher-content__data-input checkbox"
                                  />
                                </div>

                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'percent'
                                  "
                                >
                                  <percent-input v-model="value.value" />
                                </div>

                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'text-multi-line'
                                  "
                                >
                                  <textarea
                                    v-model="value.value"
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="1"
                                    class="switcher-content__text-data-textarea"
                                  ></textarea>
                                </div>
                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'date'
                                  "
                                  style="max-width: 200px"
                                >
                                  <DatePicker
                                    :format="
                                      (t, a) => formatDateFunc(t, value.value)
                                    "
                                    v-model="value.value"
                                    :enableTimePicker="false"
                                  />
                                </div>

                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'timestamp'
                                  "
                                  style="max-width: 200px"
                                >
                                  <DatePicker
                                    :format="(t) => formatDateTimeFunc(t)"
                                    :previewFormat="(t) => formatDateTimeFunc(t)"
                                    v-model="value.value"
                                    :enableTimePicker="true"
                                    :value-type="valueType.date2value"
                                  />
                                </div>

                                <div
                                  class="
                                    report-table__item-text-multiple-choice-wrap
                                  "
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'text-multiple-choice'
                                  "
                                >
                                  <template
                                    v-if="
                                      getTextMultipleChoiceType(
                                        this.objAllFields[value.key]
                                      ) == 'list'
                                    "
                                  >
                                    <div style="max-width: 250px">
                                      <Multiselect
                                        v-model="value.value"
                                        :options="
                                          this.objAllFields[value.key][
                                            'properties'
                                          ]['choices'].sort()
                                        "
                                      />
                                    </div>
                                  </template>
                                  <template
                                    v-if="
                                      getTextMultipleChoiceType(
                                        this.objAllFields[value.key]
                                      ) == 'table'
                                    "
                                  >
                                    <div style="max-width: 250px">
                                      <Multiselect
                                        v-model="value.value"
                                        :options="
                                          getDataForDropDownField(
                                            this.objAllFields[value.key][
                                              'properties'
                                            ]['masterChoiceTableId'],
                                            this.objAllFields[value.key][
                                              'properties'
                                            ]['masterChoiceFieldId']
                                          ).sort()
                                        "
                                      />
                                    </div>
                                  </template>
                                  <!-- <template v-else>
                                    <div>{{ value.value }}222222</div>
                                  </template> -->
                                  <!-- <Multiselect 
                                v-model="value.value"
                                :options="
                                  this.objAllFields[value.key].properties.choices
                                "
                                clearOnSelect="false"
                                placeholder="Choose"
                                :close-on-select="false"
                              ></Multiselect> -->
                                </div>

                                <div
                                  v-else-if="
                                    this.objAllFields[value.key].fieldType ==
                                    'currency'
                                  "
                                >
                                  <QCurrencyInput
                                    v-model="value.value"
                                    :options="{ currency: 'USD', precision: 2 }"
                                    :disabled="false"
                                  />
                                </div>

                                <div v-else-if="value.key == 136">
                                  <input
                                    v-model="value.value"
                                    @input="(e) => {
                                      value.handleInput(e, value);
                                    }"
                                    type="text"
                                    class="switcher-content__data-input"
                                  />
                                </div>

                                <div v-else>
                                  <input
                                    v-model="value.value"
                                    type="text"
                                    class="switcher-content__data-input"
                                  />
                                </div>

                              </div>
                              <div
                                v-else-if="
                                  this.getTypeForElement(value) ==
                                  'multichoice-rel'
                                "
                              >
                                <div class="switcher-content__title">
                                  {{
                                    this.objAllFields[value.labelId].fieldType !=
                                    "url"
                                      ? this.objAllFields[value.labelId].label // + " " + value.key + " | " + this.objAllFields[value.key].fieldType  + " | " + this.objAllFields[value.key].mode
                                      : ""
                                  }}
                                </div>
                                <div v-if="value.disabled" style="max-width: 250px">
                                  <Multiselect
                                    v-model="value.value"
                                    :options="sortOptionsById(tablesOptions[value.tableId], value.nameId)"
                                    :label="value.nameId"
                                    :track-by="value.nameId"
                                    :searchable="true"
                                    :disabled="value.disabled"
                                  />
                                </div>
                                <div v-else style="max-width: 250px">
                                  <Multiselect
                                    v-model="value.value"
                                    :options="sortOptionsById(tablesOptions[value.tableId], value.nameId)"
                                    :label="value.nameId"
                                    :track-by="value.nameId"
                                    :searchable="true"
                                    :disabled="this.objAllFields[value.key].disabled"
                                  />
                                </div>
                                <!-- :searchStart="true" -->

                                <!-- </Multiselect> -->

                                <!-- <multiselect v-model="value" :options="options" :custom-label="nameWithLang" placeholder="Select one" label="name" track-by="name"></multiselect> -->
                              </div>
                            </td>
                          </template>
                        </tr>
                      </table>
                    </div>
                  </div>
                <!-- <div class="switcher-content__title">{{ value }}</div> -->
                <!-- <div class="switcher-content__title">
                    {{ this.objAllFields[value.key].label }}
                  </div> -->
              </div>
            </div>
            <!-- <table class="report-table">
                <tbody>
                  <tr v-for="(value, index) in parentReportData" :key="index">
                    <td>
                      <b>{{ this.objParentFields[index].label }}</b>
                    </td>
                    <td>
                      <textarea :value="value.value"></textarea>
                    </td>
                  </tr>
                </tbody>
              </table> -->
          </div>

          <div class="modal__footer">
            <div
              class="modal__close-button"
              v-on:click="
                () => {
                  //this.recalculateHeightAndWidthForTextArea();
                  this.$emit('showStatus', false);
                }
              "
            >
              Cancel
            </div>
            <div class="modal__save-button" v-on:click="() => this.sendToQB()">
              <CustomLoader v-if="sendingToQB" />
              <span v-else>Save</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddDocumentTypeCnfPopUp
      v-if="showModalAddDocumentTypeCnf"
      :open="showModalAddDocumentTypeCnf"
      @showStatus="showStatusAddDocumentTypeCnfPopUp"
      :rid="this.rid"
      :type="this.type"
    />

    <AddDocumentTypeFobPopUp
      v-if="showModalAddDocumentTypeFob"
      :open="showModalAddDocumentTypeFob"
      @showStatus="showStatusAddDocumentTypeFobPopUp"
      :rid="this.rid"
      :type="this.type"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import AddDocumentTypeCnfPopUp from "@/components/pop-up/AddDocumentTypeCnfPopUp.vue";
import AddDocumentTypeFobPopUp from "@/components/pop-up/AddDocumentTypeFobPopUp.vue";
// import { reactive } from "@vue/reactivity";
import { reactive } from "vue";
import QCurrencyInput from "@/components/QCurrencyInput.vue";
import PercentInput from "@/components/PercentInput.vue";

import QuickbaseRepository from "@/api/Quickbase";
import LiftingRepository from "@/api/Lifting";

export default {
  name: "EditReportRecord",
  components: {
    Multiselect,
    CustomLoader,
    AddDocumentTypeCnfPopUp,
    AddDocumentTypeFobPopUp,
    QCurrencyInput,
    PercentInput,
  },
  props: {
    table: String,
    selectedReport: String,
    open: Boolean,
    rid: { type: Number, default: null },
    type: String,
    charterer: { type: Number, default: null },
    action: String,
    syncDocuments: Function,
  },
  emits: ["showStatus", "updateRecord"],
  // setup() {
  //   let dataForDropDownFields = reactive({
  //    /*  bsghxwya8: {},
  //     br6kf4scx: {},
  //     br3isn8nb: {},
  //     br3isji2e: {},
  //     brx74qi6y: {},
  //     brxi28a76: {},
      
  //     brxixhya9: {}, */
  //   });
  //   return {
  //     dataForDropDownFields,
  //   };
  // },
  setup() {
    let mapping = reactive([]);

    function updateValueByKey(key, newValue) {
      const item = findItemByKey(mapping, key);
      if (item) {
        item.value = newValue;
      }
    }

    function findItemByKey(items, key) {
      for (const section of items) {
        for (const row of section.body) {
          for (const element of row) {
            if (element.key === key) {
              return element;
            }
          }
        }
      }
      return null;
    }

    function setMappingItems(value) {
      mapping = reactive(value);
    }

    return {
      mapping,
      updateValueByKey,
      setMappingItems
    };
  },
  data: function () {
    // setup () {
    // const dataForDropDownFields = reactive({});
    return {
      // dataForDropDownFields: {},
      // dataForDropDownFields,

      clistForTables: {
        brxixhya9: [3, 6],
        brx74xr8g: [3, 7],
        brx74qi6y: [3, 9],
      },
      tablesOptions: {
        brxixhya9: [],
        brx74xr8g: [],
        brx74qi6y: [],
      },
      reports: [],
      modalName: "Add Document", // +  # " + this.rid,
      allFields: [],
      fields: [],
      masterTableId: "bsdk56xut",

      sendingToQB: false,

      selectedSwitcher: 0,

      showModalAddDocumentTypeCnf: false,
      showModalAddDocumentTypeFob: false,

      // mapping: [],
    };
  },
  mounted() {
    this.getDataTaskFields();
    this.getData();
    this.getRelData();
  },
  updated() {
    let qbUpdateEtaButton = this.$el.querySelectorAll(
      ".qb-update-eta-button .Vibrant"
    );
    qbUpdateEtaButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-eta-button");
        console.log(this);
        // let rowId = e.target.parentElement.dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        // let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETALoadPort(this.rid);
        return false;
      };
    });

    let qbUpdateEtcButton = this.$el.querySelectorAll(
      ".qb-update-etc-button .Vibrant"
    );
    qbUpdateEtcButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-etc-button");
        console.log(this);
        // let rowId = e.target.parentElement.dataset.row;
        // this.ridForAddTask = this.reportData[rowId][3].value;
        // let rid = this.reportData[rowId][3].value;
        // let fid = e.target.parentElement.dataset.fid;
        this.updateETCLoadPort(this.rid);
        return false;
      };
    });

    /* let allAddTaskBtn = document.querySelectorAll(".rich-text__249 .Vibrant");
    allAddTaskBtn.forEach((el) => {
      el.onclick = (e) => {
        let rid = e.target.parentElement.dataset.rid;
        let prop = {};
        prop["to"] = "br3qsnsv3";
        prop["data"] = [];

        let dataTmp = {};
        dataTmp["key"] = rid;
        dataTmp[22] = moment().format("YYYY-MM-DDTkk:mm:ssZ"); // m-d-Y g:i A

        prop["data"].push(dataTmp);
        console.log(prop);
        this.saveDataForQBWithoutClosePopUp(prop);
        return false;
      };
    }); */

    let allContracts = document.querySelectorAll(".rich-text__484 a");
    allContracts.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    var textarea = document.querySelectorAll(
      ".switcher-content__text-data-textarea"
    );
    textarea.forEach((el) => {
      console.log("WWWWWWWW");
      console.dir(el);
      console.log(el.value);
      console.log(el.scrollTop);
      console.log(el.scrollHeight);
      console.log(el.scrollWidth);

      el.style.height = 0;

      el.dataset.scrollTop = el.scrollTop;
      el.dataset.scrollHeight = el.scrollHeight;
      el.dataset.scrollWidth = el.scrollWidth;

      if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + 5 + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 20 + "px";
      }
      // if(el.scrollTop > 0){
      // el.style.height = el.scrollHeight + "px";
      // el.style.width = el.scrollWidth + "px";
      el.style.height = el.dataset.newHeight;
      el.style.width = el.dataset.newWidth;
      // }
    });

    /* textarea.addEventListener('keyup', function(){
      if(this.scrollTop > 0){
        this.style.height = this.scrollHeight + "px";
      }
    }); */
  },
  computed: {
    mappingNotEmpty() {
      if (this.allFields.length == 0) return false;
      return true;
    },
    objAllFields() {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    valueType() {
      return {
        value2date: (value) => {
          // transform the binding value to calendar Date Object
          console.log("value2date", value);

          return moment(value).format("LL");
        },
        date2value: (date) => {
          // transform the calendar Date Object to binding value
          console.log("date2value", date);

          return moment(date).format("LL");
        },
      };
    },
  },
  watch: {
    fields() {
      for (const key in this.mapping) {
        if (Object.hasOwnProperty.call(this.mapping, key)) {
          if (this.isShowTab(this.mapping[key])) {
            this.selectedSwitcher = key;
            break;
          }
        }
      }
    },
  },
  methods: {
    sortOptionsById(options, id) {
      const sorter = (a, b) => {
        const nameA = a[id].toUpperCase(); // ignore upper and lowercase
        const nameB = b[id].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      };
      return options.sort(sorter);
    },

    recalculateHeightAndWidthForTextArea() {
      var textarea = document.querySelectorAll(
        ".switcher-content__text-data-textarea"
      );
      textarea.forEach((el) => {
        console.log("WWWWWWWW");
        console.dir(el);
        console.log(el.value);
        console.log(el.scrollTop);
        console.log(el.scrollHeight);
        console.log(el.scrollWidth);
        el.style.height = 0;

        el.dataset.scrollTop = el.scrollTop;
        el.dataset.scrollHeight = el.scrollHeight;
        el.dataset.scrollWidth = el.scrollWidth;

        el.dataset.newHeight = el.scrollHeight + "px";
        el.dataset.newWidth = el.scrollWidth + "px";

        /* if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 10 + "px";
      } */
        // if(el.scrollTop > 0){
        // el.style.height = el.scrollHeight + "px";
        // el.style.width = el.scrollWidth + "px";
        el.style.height = el.dataset.newHeight;
        el.style.width = el.dataset.newWidth;
        // }
      });
    },
    async getDataForDropDownFields() {
      let info = {};
      for (const key in this.objAllFields) {
        if (Object.hasOwnProperty.call(this.objAllFields, key)) {
          const element = this.objAllFields[key];
          if (
            element["fieldType"] == "text-multiple-choice" &&
            Object.hasOwnProperty.call(
              element["properties"],
              "masterChoiceTableId"
            )
          ) {
            let masterChoiceTableId =
              element["properties"]["masterChoiceTableId"];
            let masterChoiceFieldId =
              element["properties"]["masterChoiceFieldId"];

            if (Object.hasOwnProperty.call(info, masterChoiceTableId)) {
              if (!info[masterChoiceTableId].includes(masterChoiceFieldId)) {
                info[masterChoiceTableId].push(masterChoiceFieldId);
              }
            } else {
              info[masterChoiceTableId] = [masterChoiceFieldId];
            }
            // info.push({fid: this.objAllFields["properties"]["masterChoiceFieldId"], table: this.objAllFields["properties"]["masterChoiceTableId"]});
          }
        }
      }
      for (const key in info) {
        if (Object.hasOwnProperty.call(info, key)) {
          const element = info[key];
          this.getDataForDropDownFieldsFromQB(key, element);
        }
      }
    },
    async getDataForDropDownFieldsFromQB(table, clist) {
      QuickbaseRepository.getRecordsByTableID(table, clist)
        .then((result) => {
          console.log(result);
          let data = result["data"]["body"]["data"];
          this.dataForDropDownFields[table] = data;
          // this.dataForDropDownFields[table].push({});
          // let data = result["body"];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getDataForDropDownField(table, fid) {
      console.log("getDataForDropDownField table:" + table + " fid:" + fid);
      let result = [];
      if (Object.hasOwnProperty.call(this.dataForDropDownFields, table)) {
        const data = this.dataForDropDownFields[table];
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const record = data[key];
            for (const fieldId in record) {
              if (Object.hasOwnProperty.call(record, fieldId)) {
                const element = record[fieldId];
                if (fieldId == fid) {
                  result.push(element["value"]);
                }
              }
            }
          }
        }
      }
      return result;
    },
    getTextMultipleChoiceType(prop) {
      if (Object.hasOwnProperty.call(prop["properties"], "choices")) {
        return "list";
      } else if (
        Object.hasOwnProperty.call(prop["properties"], "masterChoiceTableId")
      ) {
        return "table";
      } else {
        return "other";
      }
    },
    async getRelData() {
      for (const key in this.clistForTables) {
        if (Object.hasOwnProperty.call(this.clistForTables, key)) {
          if(key == "brx74xr8g") {
            this.getRelDocumentDataQB(key, this.clistForTables[key]);
          } else {
            this.getRelDataQB(key, this.clistForTables[key]);
          }
        }
      }
    },
    async getRelDataQB(tableId, clist) {
      QuickbaseRepository.getRecordsByTableID(tableId, clist)
        // .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["data"]["body"]["data"];
          // let data = result["body"];
          console.log(data);
          let newRes = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];

              let tmp = {};
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  if (k == 3) {
                    tmp["value"] = element[k].value;
                  } else {
                    tmp[k] = element[k].value;
                  }
                }
              }
              newRes.push(tmp);
            }
          }

          this.tablesOptions[tableId] = newRes;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
    async getRelDocumentDataQB(tableId, clist) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
        },
        body: JSON.stringify({
          tableId: tableId,
          clist: clist,
          rid: this.rid,
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/document/records/condition/charterer/" + this.charterer,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          let data = result["body"]["data"];
          // let data = result["body"];
          let newRes = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];

              let tmp = {};
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  if (k == 3) {
                    tmp["value"] = element[k].value;
                  } else {
                    tmp[k] = element[k].value;
                  }
                }
              }
              newRes.push(tmp);
            }
          }

          this.tablesOptions[tableId] = newRes;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
    getTypeForElement(value) {
      if (Object.hasOwnProperty.call(value, "componentType")) {
        return value["componentType"];
      } else {
        return "";
      }
    },
    isShowElement(value) {
      if (Object.hasOwnProperty.call(value, "componentType")) {
        if (value["componentType"] == "documentReport15") {
          if (
            Object.hasOwnProperty.call(this.fields, 469) &&
            this.fields[469]["value"]
          )
            return true;
          else return false;
        }
        if (value["componentType"] == "documentReport16") {
          if (
            Object.hasOwnProperty.call(this.fields, 470) &&
            this.fields[470]["value"]
          )
            return true;
          else return false;
        }
      }
      return true;
    },
    isShowTab(value) {
      // if(this.fields.length == 0) return true;
      // "Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract", "Dates", "", ""
      switch (value.id) {
        default:
          break;
      }
      return true;
    },

    formatDateFunc(date, a) {
      // console.log("+++formatDateFunc+++")
      console.log(a);
      // console.log(date)
      // console.log(date.toUTCString())
      // console.log(date.toISOString())
      // console.log( moment(date).tz("US/Pacific").toString())
      if (date) {
        return moment(date).format("MM-DD-YYYY"); //.tz("US/Pacific")
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date) {
      if (date) {
        return (
          moment(date)
            // .tz("US/Pacific")
            .format("MM-DD-yyyy h:mm a")
        ); // America/New_York
      } else {
        return "";
      }
    },

    updateValuesForFields() {
      // if(!this.fields.length) return;
      this.mapping.forEach((tabs) => {
        tabs.body.forEach((subTubs) => {
          subTubs.body.forEach((row) => {
            row.forEach((field) => {
              if (Object.hasOwnProperty.call(field, "key") &&
                !Object.hasOwnProperty.call(field, "componentType")) {
                // console.log(field.key + " =|= " + field.value);
                this.fields[field.key]["value"] = field.value;
              }
            });
          });
        });
      });
    },

    isEmpty(str) {
      if (str == "" || str == null || str === 0) return true;
      return false;
    },

    showField(record) {
      if (Object.hasOwnProperty.call(record, "show")) {
        if(record.show == false) {
          return false;
        }
      }
      console.log("=== showField ===");
      // console.log(record);
      this.updateValuesForFields();
      // let res = true;
      // 103, 526
      let key = record.key;
      if (Object.hasOwnProperty.call(record, "labelId")) {
        key = record.labelId;
      }
      // console.log(key);

      try {
        switch (key) {
          // rule 
          case 45:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;

          case 134:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return true;
            else return false;

          case 136:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return true;
            else return false;

          // rule 
          case 97:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 112:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 101:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 102:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 103:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 104:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 105:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 106:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 107:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 108:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 109:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 87:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 89:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 121:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 119:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 122:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 96:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 110:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 133:
            if (this.fields[6]["value"] == "Purchase: Fob")
              return true;
            else return false;

            // #1
          case 54:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 55:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 69:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 70:
            if ((this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")) {
              return false;
            }
            else {
              if(this.fields[69]["value"] == true) {
                return true;
              }
              return false;
            }
            /* case 41:
              if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
                return false;
              else return true; */
          case 30:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 32:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 33:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 40:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 39:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 31:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 48:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;
          case 49:
            if (this.fields[6]["value"] == "Sale: Cnf" || this.fields[6]["value"] == "Purchase: Cnf")
              return false;
            else return true;

            // #2
          case 24:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 25:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 67:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 68:
            if ((this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")) {
              return false;
            }
            else {
              if(this.fields[67]["value"] == true) {
                return true;
              }
              return false;
            }
          case 38:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 37:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 57:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 35:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 36:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 18:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 19:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 29:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
            /* case 87:
              if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
                return false;
              else return true;
            case 89:
              if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
                return false;
              else return true; */
          case 44:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 66:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob" || !this.isEmpty(this.fields[29]["value"]))
              return false;
            else return true;
          case 78:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 76:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;

          case 99:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 111:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 100:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 116:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;
          case 41:
            if (this.fields[6]["value"] == "Sale: Fob" || this.fields[6]["value"] == "Purchase: Fob")
              return false;
            else return true;

            // #3
          case 26:
            if (this.fields[67]["value"] == true)
              return true;
            else return false;
          case 27:
            if (this.fields[67]["value"] == true)
              return true;
            else return false;
          case 20:
            if (this.fields[67]["value"] == true)
              return true;
            else return false;
          case 21:
            if (this.fields[67]["value"] == true)
              return true;
            else return false;
            /* case 68:
              if (this.fields[67]["value"] == true)
                return true;
              else return false; */

            // #4
          case 42:
            if (this.fields[68]["value"] == true)
              return true;
            else return false;
          case 43:
            if (this.fields[68]["value"] == true)
              return true;
            else return false;
          case 22:
            if (this.fields[68]["value"] == true)
              return true;
            else return false;
          case 23:
            if (this.fields[68]["value"] == true)
              return true;
            else return false;

            // #5
          case 58:
            if (this.fields[69]["value"] == true)
              return true;
            else return false;
          case 59:
            if (this.fields[69]["value"] == true)
              return true;
            else return false;
          case 50:
            if (this.fields[69]["value"] == true)
              return true;
            else return false;
          case 51:
            if (this.fields[69]["value"] == true)
              return true;
            else return false;
            /* case 70:
              if (this.fields[69]["value"] == true)
                return true;
              else return false; */

            // #6
          case 63:
            if (this.fields[70]["value"] == true)
              return true;
            else return false;
          case 64:
            if (this.fields[70]["value"] == true)
              return true;
            else return false;
          case 52:
            if (this.fields[70]["value"] == true)
              return true;
            else return false;
          case 53:
            if (this.fields[70]["value"] == true)
              return true;
            else return false;

            // #7
            /* case 66:
              if (
                !this.isEmpty(this.fields[29]["value"]) // change to 84
              )
                return false;
              else return true; */
          case 84:
            if (
                !this.isEmpty(this.fields[29]["value"]) // change to 84
            )
              return true;
            else return false;

          default:
            break;
        }
      } catch (error) {
        return true;
      }
      return true;
    },

    getObjArrWithIdKey(fields) {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      fields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    getData() {
      this.mapping = [
        {
          id: 1,
          title: "Nomination",
          show: false,
          body: [
            {
              title: "General",
              showTitle: false,
              body: [
                /* [
                  {
                    componentType: "multichoice-rel",
                    labelId: 93,
                    nameId: 6, // lookupTargetFieldId
                    forFieldId: 93, // lookupReferenceFieldId
                    tableId: "brxixhya9", // masterTableId
                    key: 93,
                    width: 50,
                    value: "",
                  },
                ],
                [
                  {
                    key: 95,
                    width: 100,
                    value: "",
                  },
                ], */
                /* [{
                    key: 93,
                    width: 100,
                    value: "",
                  },], */
                /* [
                    {
                      componentType: "multichoice-rel",
                      labelId: 93,
                      nameId: 6, // lookupTargetFieldId
                      forFieldId: 93, // lookupReferenceFieldId
                      tableId: "brxixhya9", // masterTableId
                      key: 93,
                      width: 50,
                      value: "",
                    },
                ], */
                [
                  {
                    key: 6,
                    width: 50,
                    value: this.type,
                  },
                  {
                    key: 8,
                    width: 100,
                    value: "",
                  },
                  /* {
                    key: 10,
                    width: 100,
                    value: "",
                  }, */
                ],
              ],
            },
            {
              title: "Trade",
              showTitle: true,
              body: [
                [
                  {
                    componentType: "multichoice-rel",
                    labelId: 96,
                    nameId: 7, // lookupTargetFieldId
                    forFieldId: 28, // lookupReferenceFieldId
                    tableId: "brx74xr8g", // masterTableId
                    key: 28,
                    width: 50,
                    value: "",
                  },
                  // {
                  //   componentType: "multichoice-rel",
                  //   labelId: 30,
                  //   nameId: 7, // lookupTargetFieldId
                  //   forFieldId: 30, // lookupReferenceFieldId
                  //   tableId: "brx74xr8g", // masterTableId
                  //   key: 30,
                  //   width: 50,
                  //   value: "",
                  // },
                  {
                    componentType: "multichoice-rel",
                    labelId: 31,
                    nameId: 7, 
                    forFieldId: 30, 
                    tableId: "brx74xr8g",
                    key: 30,
                    width: 50,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 32,
                    nameId: 7, // lookupTargetFieldId
                    forFieldId: 32, // lookupReferenceFieldId
                    tableId: "brx74xr8g", // masterTableId
                    key: 32,
                    width: 50,
                    value: "",
                  },
                ],
                [
                  {
                    key: 18,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 19,
                    width: 100,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 24,
                    nameId: 9, // lookupTargetFieldId
                    forFieldId: 24, // lookupReferenceFieldId
                    tableId: "brx74qi6y", // masterTableId
                    key: 24,
                    width: 50,
                    value: "",
                  },
                  {
                    key: 67,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 20,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 21,
                    width: 100,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 27,
                    nameId: 9, // lookupTargetFieldId
                    forFieldId: 27, // lookupReferenceFieldId
                    tableId: "brx74qi6y", // masterTableId
                    key: 27,
                    width: 50,
                    value: "",
                  },
                  {
                    key: 68,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 22,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 23,
                    width: 100,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 42,
                    nameId: 9, // lookupTargetFieldId
                    forFieldId: 42, // lookupReferenceFieldId
                    tableId: "brx74qi6y", // masterTableId
                    key: 42,
                    width: 50,
                    value: "",
                  },
                ],

                [
                  {
                    key: 48,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 49,
                    width: 100,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 54,
                    nameId: 9, // lookupTargetFieldId
                    forFieldId: 54, // lookupReferenceFieldId
                    tableId: "brx74qi6y", // masterTableId
                    key: 54,
                    width: 50,
                    value: "",
                  },
                  {
                    key: 69,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 50,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 51,
                    width: 100,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 58,
                    nameId: 9, // lookupTargetFieldId
                    forFieldId: 58, // lookupReferenceFieldId
                    tableId: "brx74qi6y", // masterTableId
                    key: 58,
                    width: 50,
                    value: "",
                  },
                  {
                    key: 70,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 52,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 53,
                    width: 100,
                    value: "",
                  },
                  {
                    componentType: "multichoice-rel",
                    labelId: 63,
                    nameId: 9, // lookupTargetFieldId
                    forFieldId: 63, // lookupReferenceFieldId
                    tableId: "brx74qi6y", // masterTableId
                    key: 63,
                    width: 50,
                    value: "",
                  },
                ],

                // [
                //   {
                //     componentType: "multichoice-rel",
                //     labelId: 28,
                //     nameId: 7, // lookupTargetFieldId
                //     forFieldId: 28, // lookupReferenceFieldId
                //     tableId: "brx74xr8g", // masterTableId
                //     key: 28,
                //     width: 50,
                //     value: "",
                //   },
                // ],
                [
                  {
                    key: 34,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 37,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 38,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 39,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 40,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 44,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 110,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 133,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            },
            {
              title: "Vessel",
              showTitle: true,
              body: [
                [
                  {
                    key: 66,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 78,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 76,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 97,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 112,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 101,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            },
            {
              title: "Discharge",
              showTitle: true,
              body: [
                [
                  {
                    key: 35,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 36,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 102,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 104,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 106,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 108,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 103,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 105,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 107,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 109,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 87,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            },
            {
              title: "Discharge2",
              showTitle: false,
              body: [
                [
                  {
                    key: 89,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 121,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 119,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 122,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            },
            {
              title: "Laytime",
              showTitle: true,
              body: [
                [
                  {
                    key: 99,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 111,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 100,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 116,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 41,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            },
            {
              title: "Price",
              showTitle: true,
              body: [
                [
                  {
                    key: 45,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 134,
                    width: 100,
                    value: "",
                  },
                  {
                    key: 136,
                    width: 100,
                    value: "",
                    handleInput: (event, value) => {
                      const startValue = value.value;
                      const inputValue = event.data;
                      const isEnglishLetterReg = /^[a-zA-Z]$/;
                      if (inputValue.length > 0) {
                        if(isEnglishLetterReg.test(inputValue)) {
                          value.value = event.data.slice(0, 1);
                        } else {
                          value.value = startValue.slice(0, 1);
                          if(!isEnglishLetterReg.test(value.value)) value.value = "";
                        }
                      } else {
                        value.value = "";
                      }
                      event.target.value = value.value;
                    },
                  },
                ],
                [
                  {
                    key: 46,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 120,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            },
            /* {
              title: "Differentials",
              showTitle: true,
              body: [
                [
                  {
                    key: 87,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 89,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            }, */
            /* {
              title: "Reminders",
              showTitle: true,
              body: [
                [
                  {
                    key: 57,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 60,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 7,
                    width: 100,
                    value: "",
                    show: false,
                  },
                ],
                [
                  {
                    key: 80,
                    width: 100,
                    value: "",
                  },
                ],
                [
                  {
                    key: 82,
                    width: 100,
                    value: "",
                  },
                ],
              ],
            }, */
          ],
        },
      ];

      // this.setMappingItems(tmpmapping);
      // this.updateValueByKey(136, "t4est");

      let params = {};
      params["key"] = this.rid;
      // params["key"] = 422;
      params["clist"] = [];

      for (const header in this.mapping) {
        if (Object.hasOwnProperty.call(this.mapping, header)) {
          for (const subTab in this.mapping[header]["body"]) {
            if (
              Object.hasOwnProperty.call(this.mapping[header]["body"], subTab)
            ) {
              for (const keyRow in this.mapping[header]["body"][subTab][
                "body"
              ]) {
                if (
                  Object.hasOwnProperty.call(
                    this.mapping[header]["body"][subTab]["body"],
                    keyRow
                  )
                ) {
                  for (const key in this.mapping[header]["body"][subTab][
                    "body"
                  ][keyRow]) {
                    if (
                      Object.hasOwnProperty.call(
                        this.mapping[header]["body"][subTab]["body"][keyRow],
                        key
                      )
                    ) {
                      params["clist"].push(
                        this.mapping[header]["body"][subTab]["body"][keyRow][
                          key
                        ].key
                      );
                    }
                  }
                  /* params["clist"].push(
                    this.mapping[header]["body"][subTab]["body"][keyRow].key
                  ); */
                }
              }
            }
          }
        }
      }
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
        },
        body: JSON.stringify(params),
      };
      console.log(requestOptions);

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/document/query",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          let fields = result["body"]["fields"];
          if (data.length) {
            data = data.shift();
          } else {
            fields.forEach(element => {
              this.fields[element.id] = {value: ""};
            });
          }
          for (const header in this.mapping) {
            if (Object.hasOwnProperty.call(this.mapping, header)) {
              for (const subTab in this.mapping[header]["body"]) {
                if (
                  Object.hasOwnProperty.call(
                    this.mapping[header]["body"],
                    subTab
                  )
                ) {
                  for (const keyRow in this.mapping[header]["body"][subTab][
                    "body"
                  ]) {
                    if (
                      Object.hasOwnProperty.call(
                        this.mapping[header]["body"][subTab]["body"],
                        keyRow
                      )
                    ) {
                      for (const key in this.mapping[header]["body"][subTab][
                        "body"
                      ][keyRow]) {
                        if (
                          Object.hasOwnProperty.call(
                            this.mapping[header]["body"][subTab]["body"][
                              keyRow
                            ],
                            key
                          )
                        ) {
                          let keyForField =
                            this.mapping[header]["body"][subTab]["body"][
                              keyRow
                            ][key].key;

                            if(keyForField == 93) {
                              this.mapping[header].body[subTab].body[keyRow][
                                key
                              ].value = this.charterer;
                            }

                          // if (
                          //   this.getObjArrWithIdKey(fields)[keyForField].type ==
                          //     "timestamp" &&
                          //   data[keyForField].value
                          // ) {
                          //   this.mapping[header].body[subTab].body[keyRow][
                          //     key
                          //   ].value = moment(data[keyForField].value)
                          //     .tz("US/Pacific")
                          //     .format("MM-DD-yyyy h:mm a");
                          // } else if (
                          //   this.getObjArrWithIdKey(fields)[keyForField].type ==
                          //     "date" &&
                          //   data[keyForField].value
                          // ) {
                          //   this.mapping[header].body[subTab].body[keyRow][
                          //     key
                          //   ].value = moment(data[keyForField].value).format(
                          //     "MM-DD-yyyy"
                          //   );
                          // } else {
                          //   this.mapping[header].body[subTab].body[keyRow][
                          //     key
                          //   ].value = data[keyForField].value;
                          // }
                        }
                      }
                      // this.set(this.mapping[header]["body"][subTab]["body"][key], "value", data[
                      //   this.mapping[header]["body"][subTab]["body"][key].key
                      // ].value);
                    }
                  }
                }
              }
              // this.set(this.mapping, header, this.mapping[header]);
            }
          }

          /* this.reportData = result["body"]["data"];
          this.fields = result["body"]["fields"];
          this.allFields = result["body"]["allFields"];
          this.clist = result["body"]["report"]["query"]["fields"];
          this.relationships = result["body"]["relationships"];
          this.gettingReport = false; */
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
    showStatusAddDocumentTypeCnfPopUp(status) {
      this.showModalAddDocumentTypeCnf = status;
    },
    showStatusAddDocumentTypeFobPopUp(status) {
      this.showModalAddDocumentTypeFob = status;
    },
    getDataTaskFields() {
      QuickbaseRepository.getTableInfo(this.masterTableId)
        .then((result) => {
          this.allFields = result["data"]["body"]["allFields"];
          this.getDataForDropDownFields();
        })
        .catch((error) => console.log("error", error));
    },
    sendToQB() {
      if (!this.sendingToQB) {
        console.log("action", this.action)
        this.sendingToQB = true;
        let prop = {};
        prop["to"] = this.masterTableId;
        prop["data"] = [];

        let dataTmp = {};
        dataTmp[7] = this.rid;

        if (this.charterer) {
          dataTmp[93] = this.charterer;
        }

        this.mapping.forEach((tabs) => {
          tabs.body.forEach((subTubs) => {
            subTubs.body.forEach((row) => {
              row.forEach((field) => {
                if (Object.hasOwnProperty.call(field, "key")) {
                  if (
                    // field.value != "" &&
                    // field.value != null &&
                    (this.objAllFields[field.key].mode == "" &&
                      this.objAllFields[field.key].fieldType != "dblink") /*  &&
                      this.objAllFields[field.key].fieldType !=
                        "text-multiple-choice" */ ||
                    //&& this.objAllFields[field.key].doesDataCopy
                    this.objAllFields[field.key].fieldType == "recordid"
                  ) {
                    if (this.objAllFields[field.key].fieldType == "date") {
                      if (field.value != "" && field.value != null) {
                        dataTmp[field.key] = moment(field.value).format(
                          "YYYY-MM-DD"
                        );
                      } else {
                        dataTmp[field.key] = "";
                      }
                    } else if (
                      this.objAllFields[field.key].fieldType == "timestamp"
                    ) {
                      if (field.value != "" && field.value != null) {
                        dataTmp[field.key] = moment(field.value).format(
                          //2019-12-18T12:00:00 YYYY-MM-DD h:mm a
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                      } else {
                        dataTmp[field.key] = "";
                      }
                    } else {
                      dataTmp[field.key] = field.value;
                    }
                  }
                }
              });
              /* console.log(row);
              if (Object.hasOwnProperty.call(row, "key")) {
                if (
                  (row.value != "" &&
                    row.value != null &&
                    this.objAllFields[row.key].mode == "" &&
                    this.objAllFields[row.key].doesDataCopy &&
                    this.objAllFields[row.key].fieldType !=
                      "text-multiple-choice") ||
                  this.objAllFields[row.key].fieldType == "recordid"
                ) {
                  dataTmp[row.key] = row.value;
                }
              } */
            });
          });
          /* if (el.value != "") {
            dataTmp[el.key] = el.value;
          } */
        });
        prop["data"].push(dataTmp);

        if (this.action == "Add lifting") {
          this.saveDataToLocalStorage(prop)
        } else {
          this.saveDataForQB(prop);
        }

      }
      // this.$emit('showStatus', false);
    },

    async saveDataForQBWithoutClosePopUp(params) {
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log(result);
          // this.clients = result;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    async saveDataForQB(params) {
      QuickbaseRepository.pushDataToQB(params)
        .then((result) => {
          console.log(result);
          this.$emit("showStatus", false);
          this.$emit("updateRecord", this.rid);
          // this.clients = result;
        })
        .catch((error) => {
          this.sendingToQB = false;
          console.log("error", error);
        });
    },

    saveDataToLocalStorage(params) {
      let documents = localStorage.getItem("documents");

      if (!documents) {
        documents = [];
      } else {
        documents = JSON.parse(documents);
      }

      const filteredData = Object.fromEntries(
        Object.entries(params.data[0]).filter(([, value]) => value !== "")
      );

      documents.push(filteredData);

      localStorage.setItem("documents", JSON.stringify(documents));

      this.$emit("showStatus", false);

      this.syncDocuments();

      console.log("documents:", documents);
    },

    updateETALoadPort(rid) {
      LiftingRepository.updateETALoadPort(rid)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    updateETCLoadPort(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
        },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "c/record/" + rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
   },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* * {
  box-sizing: border-box;
} */
* {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.modal {
  position: absolute;
}

.modal__mask {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: table; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transition: opacity 0.3s ease;
  padding: 20px;
}

.modal__wrapper {
  margin: 40px;
  /* margin-right: 56px; */
  margin-right: 16px;
  /* margin-bottom: 0; */
  overflow-y: auto;
  max-height: 100%;
  /* padding: 20px; */
}

.modal__container {
  /* overflow-y: scroll; */
  /* width: 300px; */
  margin: 0px auto;
  padding: 20px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  /* max-width: 800px; */
  width: 1270px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 95%;
}

.modal__container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.modal__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

@media screen and (min-width: 1200px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    margin: 0px auto;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: auto;
    max-width: 100%;
  }
  
  .modal__wrapper {
    margin-right: 40px;
    width: 100%;
    max-width: 1700px;
  }
}

@media screen and (max-width: 1680px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    /* width: 1070px; */
  }
}

@media screen and (max-width: 1200px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    margin: 0px auto;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: auto;
    max-width: 1000px;
  }
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d3dfed;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  flex: 0 0 auto;
}

.modal__header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: center;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  margin: 20px 0;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
  flex: 0 0 auto;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  margin-right: 15px;
  cursor: pointer;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 55px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switch-bar {
  display: flex;
  flex: 0 0 auto;
}

.switch-bar__item {
  flex: 1 1 auto;
  text-align: center;
  border-bottom: 1px solid #d3dfed;
  padding: 8px 8px;
  cursor: pointer;
}

.switch-bar__item:hover {
  border-bottom: 2px solid #2d375725;
}

.switch-bar__item_active {
  border-bottom: 2px solid #2d3757;
}

.switcher-content {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.switcher-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.switcher-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.switcher-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.switcher-content__choose {
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.switcher-content__sub-tab-body {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: end; */
  /* overflow: auto; */
  /* overflow-x: auto; */
}

.disabled {
  background: #eee;
}

.switcher-content__sub-tab-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-bottom: #2d3757 1px solid;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
}

.switcher-content__item_25 {
  box-sizing: border-box;
  width: 25%;
  padding: 20px 20px;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  /* width: 100%; */
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  /* box-sizing: content-box; */
  display: flex;
  box-sizing: border-box;
  vertical-align: bottom;
  /* box-sizing: initial; */
  /* display: table-column; */
  padding: 10px;
  /* word-wrap: break-word; */
  white-space: pre;
  /* padding-bottom: 0; */
  /* width: 100%; */
}

.switcher-content__text-data-textarea.disabled {
  /* resize: none; */
  outline: none;
  /* border: none; */
  /* border-left: 1px solid #777; */
  /* border-right: 1px solid #777; */
  /* border-bottom: 1px solid #777; */
  /* margin: 0; */
  /* padding: 0; */
  /* position: absolute; */
  /* top: 40px; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0;  */
  /* width:-moz-available; height:100%;  */
  resize: none;
}

.url-btn {
  display: block;
  border: 1px solid #2d3757;
  background: #2d375715;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.url-btn:hover {
  color: #fff;
  background: #333;
}

.switcher-content__sub-tab-table {
  /* width: 100%; */
  width: max-content
}

.switcher-content__sub-tab-table td {
  min-width: 240px;
  padding: 5px;
  border: 5px solid transparent;
}

.switcher-content__sub-tab-table td * {
  box-sizing: border-box;
  max-width: 100%;
}
.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}
</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.dp-custom-input {
  font-size: 12px !important;
  width: auto !important;
}
.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}

/* .report-body {
  width: 1100px;
}

@media screen and (max-width: 1200px) {
  .report-body {
    width: 700px;
  }
} */
</style>

import ApiHelper from "@/api/ApiHelper"

export default {
    async getTableFields() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        // let response = await Api.get(url, 
        //     data, headers);

        // let sessionID = store.state.sessionRecord.sessionID;
        let url = "/v1/lifting/columns";

        return await ApiHelper.getResult(url, data, headers);
    },

    async createReport(name, table, clist, filter, sorting) {
        var headers = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
            name: name,
            table: table,
            clist: clist,
            filter: filter,
            sorting: sorting,
        })
        
        let url = "/v1/lifting/report";

        return await ApiHelper.getResult(url, data, headers);
    },

    async updateReport(reportID, name, clist, stick, groups, filter, sorting) {
        var headers = {
            method: "PUT",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
            name: name,
            clist: clist,
            sticky_columns: stick,
            groups: groups,
            filter: filter,
            sorting: sorting,
        })
        
        let url = "/v1/lifting/reports/" + reportID;

        return await ApiHelper.getResult(url, data, headers);
    },

    async getReport(reportID) {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })

        let url = "/v1/lifting/reports/" + reportID;
        return await ApiHelper.getResult(url, data, headers);
    },

    async getReports() {
        // let sessionID = store.state.sessionRecord.sessionID;
        
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        let url = "/v1/lifting/reports";
        return await ApiHelper.getResult(url, data, headers);
    },

    async deleteReport(reportID) {
        var headers = {
            method: "DELETE",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        let url = "/v1/lifting/reports/" + reportID;

        return await ApiHelper.getResult(url, data, headers);
    },

    async getReportsData() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
          };

        let data = JSON.stringify({
            token: localStorage.token,
        })
        
        let url = "/v1/lifting/reports/data";

        return await ApiHelper.getResult(url, data, headers);
    },
}
<template>
  <div class="report-panel">
    <div
      @click="showBody = !showBody"
      class="report__title"
    >
      <div class="report__collapse">
        <span v-if="showBody">-</span>
        <span v-else>+</span>
      </div>
      <h2>{{ title }}</h2>
    </div>

    <div v-if="showBody" class="report__body">
      <table class="report-table">
        <thead>
          <tr class="report-table__head">
            <th class="report-table__sticky report-table__col-pencil"></th>
            <template v-for="(item, columnIndex) in records[0]" :key="item">
              <th
              :class="{
                  'report-table__sticky': stickyColumns.includes(columnIndex),
                }"
                :style="{ backgroundColor: getItemColor(columnIndex) }"
              >
                <div>
                  {{ columnIndex }}
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="(record, /*recordIndex*/) in records" :key="record">
            <tr>
              <td class="report-table__sticky report-table__col-pencil">
                <div
                  class="record__edit-button"
                  @click="() => {
                    this.$router.push({ name: 'LiftingRecord', params: { rid: record['rid'], open: true } })
                    }"
                ></div>
              </td>
              <template v-for="(item, columnIndex) in record" :key="item">
                <td
                  :class="{
                    'report-table__sticky': stickyColumns.includes(columnIndex),
                  }"
                  :style="{ backgroundColor: getItemColor(columnIndex) }"
                >
                  <div class="report-table__item-wrap">
                    {{ item }}
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!-- <div class="header">
      <div>{{ records }}</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'LiftingReport',
  components: {
  },
  props: {
    reportId: String,
    title: String,
    records: Array,
    stickyColumns: Array,
    groups: Array,
  },
  mounted() {
    this.culcStickyColumns();
  },
  updated() {
    this.culcStickyColumns();
  },
  data() {
    return {
      // table: "",
      // report: "",
      showBody: true,
    }
  },
  methods: {
    culcStickyColumns() {
      let rts = this.$el.querySelectorAll("table tr");

      for (const key in rts) {
        if (Object.hasOwnProperty.call(rts, key)) {
          const trElement = rts[key];
          let tds = trElement.querySelectorAll(".report-table__sticky");
          let left = 0;
          for (const tdKey in tds) {
            if (Object.hasOwnProperty.call(tds, tdKey)) {
              const tdElement = tds[tdKey];
              tdElement.style.left = left + "px";
              tdElement.dataset.test = left + "px";
              // tdElement.style.backgroundColor = "red";
              left += tdElement.offsetWidth;
            }
          }
          // trElement.style.left = "20px";
        }
      }
    },

    getGroupByItemId(itemId) {
      const group = this.groups.find(g => g.range.includes(itemId));
      return group ? { group, color: group.color } : null;
    },

    getItemColor(itemId) {
        const groupInfo = this.getGroupByItemId(itemId);
        return groupInfo ? groupInfo.color : "white";
    },
  }
}
</script>

<style scoped>
.report-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.report__body {
  /* height: 100%; */
  /* height: 700px; */
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
  /* display: flex; */
  padding-bottom: 45px;
}

.report__collapse {
  border: 3px solid #d3dfed;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  margin-right: 15px;
  color: #2d3757;
  background: #f6faff;
}

td {
  /* border-right: 1px solid #d3dfed; */
  border-bottom: 1px solid #d3dfed;
  /* padding: 15px; */
}

tr td {
  border-right: 1px solid #d3dfed4c;
}

tr td:last-child {
  border-right: 1px solid #d3dfed;
}



thead {
  /* background: #f6faff; */
  background: #fff;
}

tbody {
  /* border-top: 1px solid #d3dfed; */
  /* border-left: 1px solid #d3dfed; */
}

tbody tr:first-child td {
  border-top: 1px solid #d3dfed;
  /* border-left: 1px solid #d3dfed; */
}

tbody tr:first-child td:first-child {
  border-top: none;
  /* border-left: 1px solid #d3dfed; */
}

.report-table {
  /* margin-top: 50px; */
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
  /* height: 90vh; */
  position: relative;
}

.report-table .report-table__head th {
  padding: 0 10px;
}

.report-table .report-table__head th > div {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.report-table td div {
  white-space: nowrap;
}

.report-table__head {
  position: sticky;
  z-index: 1;
}

.btn-save {
  display: flex;
  justify-content: end;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: none;
}

.report-table tbody tr th.report-table__col-pencil {
  background: #fff;
}

.report-table__col-pencil {
  border: 0;
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  border: 0;
}

.report-table__sticky {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  /* left: 0; */
  /* background: #f6faff; */
  background: #fff;
  /* border: #2d3757 2px solid; */
  /* border-right: 1px solid #CCC; */
  /* border-left: 1px solid #CCC; */
  left: 0;
  z-index: 998;
}

td.report-table__sticky {
  background: #fff;
}

th.report-table__col-pencil {
  background: #fff;
}
.report-table__sticky > div {
  /* border: #2d3757 2px solid; */
}

.report-table__sticky > div {
  /* border: #2d3757 2px solid; */
}

.report-table__sticky > div::after,
.report-table__sticky > div::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.report-table__sticky > div::before {
  top: -1px;
  border-top: 1px solid #d3dfed;
  /* margin-left: -1px; */
}

th.report-table__sticky > div::before {
  border-top: 0px solid #d3dfed;
  /* margin-left: -1px; */
}

.report-table__sticky > div::after {
  bottom: -1px;
  border-bottom: 1px solid #d3dfed;
}

.report-table__sticky::after,
.report-table__sticky::before {
  content: "";
  position: absolute;
  top: -1px;
  height: 100%;
}

.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #d3dfed4c;
  /* margin-left: -1px; */
}

.report-table__sticky::after {
  right: -1px;
  border-right: 1px solid #d3dfed4c;
}

th.report-table__sticky::before {
  left: -1px;
  border-left: 1px solid #f6faff4c;
  /* margin-left: -1px; */
}

th.report-table__sticky::after {
  right: 0;
  border-right: 1px solid #f6faff4c;
}

.report-table tbody tr:hover td.report-table__col-pencil .record__edit-button {
  background: #eee;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0;
  flex-basis: 16px;
  background: url(@/assets/icon-pencil.svg) no-repeat center center;
  background-size: 16px 16px;
  cursor: pointer;
}

.report-table tbody tr:hover td.report-table__col-pencil {
  background: #fff;
}

.report-table tbody tr:hover td{
  background: #f2f7fc;
}

.report-table__item-wrap {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
  
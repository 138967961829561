import ApiHelper from "@/api/ApiHelper"

export default {
    async getData() {
        var headers = {
            method: "GET",
            redirect: "follow",
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": "Bearer " + localStorage.token, 
            },
        };

        let data = JSON.stringify({
            token: localStorage.token,
        });

        let url = "/v1/metric/lifting";

        return await ApiHelper.getResult(url, data, headers);
    },
}
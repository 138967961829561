<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="app">
    <div class="app-menu" v-if="showHeader && !isLoading">
      <Header />
    </div>
    <div class="app-body" v-if="!isLoading">
      <router-view />
    </div>
    <Notification :items="notif" />
    <template v-if="sessionID">
      <div id="record-controller">
        <div class="wrap">
          <div>prev</div>
          <div>
            play
          </div>
          <div>next</div>
        </div>
      </div>
    </template>
    <template v-if="sessionID">
      <div id="custom-cursor" :style="cursorStyle"></div>
    </template>
  </div>
</template>

<script>
import Header from "./components/layout/Header.vue";
import Notification from "@/components/notification/Notification.vue";
import socketService from './service/SocketService';

export default {
  name: "App",
  components: {
    Header,
    Notification,
  },
  data() {
    return {
      updatePageAfter: 60 * 5 * 1000,
      notif: [],
      sessionID: null,
      cursorPosition: { x: 0, y: 0 },

      scrollData: {},
      isLoading: true,
    };
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    this.sessionID = params.get('session-id');
    this.$store.dispatch('sessionRecord/setSessionID', this.sessionID);

    socketService.connect();

    if (!this.sessionID) {
      this.isLoading = false;

      document.addEventListener('click', this.handleEvent);
      document.addEventListener('focus', this.handleEvent, true);
      document.addEventListener('blur', this.handleEvent, true);
      document.addEventListener('keydown', this.handleEvent, true);
      document.addEventListener('scroll', this.handleEvent, true);
    } else {
      this.fetchRecord(this.sessionID).then(() => {
        // this.isLoading = false; 
      });
      document.addEventListener('wheel', function (event) {
        event.preventDefault();
      }, { passive: false });

      // document.querySelector(".app-menu").addEventListener('click', function(event) {
      //   event.preventDefault();
      //   event.stopPropagation();
      // }, true);

      // document.querySelector(".app-body").addEventListener('click', function(event) {
      //   console.log("TEST CLICK ===========");
      //   event.preventDefault();
      //   event.stopPropagation();
      // }, true);
    }
  },
  beforeRouteUpdate(to, from, next) {
    // this.sessionID = params.get('session-id');

    this.handleRouteChange(to, from);
    next();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleEvent);
    document.removeEventListener('focus', this.handleEvent, true);
    document.removeEventListener('blur', this.handleEvent, true);
    document.removeEventListener('keydown', this.handleEvent, true);
    document.removeEventListener('scroll', this.handleEvent, true);

    socketService.disconnect();
  },
  watch: {
    '$route'(to, from) {
      this.handleRouteChange(to, from);
    }
  },
  computed: {
    showHeader() {
      let res = true;

      let hide = [
        "Singin"
      ];
      if (hide.includes(this.$route.name)) res = false;

      return res;
    },
    cursorStyle() {
      return {
        top: `${this.cursorPosition.y}px`,
        left: `${this.cursorPosition.x}px`
      };
    },
  },
  methods: {
    handleRouteChange(to, from) {
      if (!this.sessionID) {
        this.handleEvent({
          type: 'route-change',
          target: document.body
        });

        console.log('Route changed from', from.fullPath, 'to', to.fullPath);
      }
    },

    checkUserActive() {
      let app = document.querySelector(
        "#app"
      );

      app.addEventListener('mousemove', this.mouseMonitor);

      setInterval(this.updatePage, 5000);
    },

    async mouseMonitor() {
      this.lastActiveTime = (new Date()).getTime();
    },

    async updatePage() {
      if ((this.lastActiveTime + this.updatePageAfter) < (new Date()).getTime()) {
        location.reload();
      }
    },

    async fetchRecord(sessionID) {
      try {
        // Викликаємо екшн getReport і передаємо параметри
        await this.$store.dispatch('sessionRecord/getRecordsBySessionID', { sessionID: sessionID }).then(async () => {
          await this.sleep(3000);
          this.playRecord()

          this.isLoading = false;

        });
        console.log('Report fetched successfully');
      } catch (error) {
        console.error('Error fetching report:', error);
      }
    },

    async playRecord() {
      console.log("playRecord");
      let records = this.$store.getters['sessionRecord/records'];
      await this.sleep(5000);
      console.log("playRecord2");
      console.log(records);

      for (const key in records) {
        this.$store.dispatch('sessionRecord/setStep', key);
        // const element = records[key];
        const element = this.$store.getters['sessionRecord/getRecordByStep']();
        this.windowResize(element.window_size_width, element.window_size_height);
        console.log("playRecord for " + key);
        console.log(element);

        this.highlightElement(element.xpath);

        this.applyScrollToElement(element.xpath, element.scroll_top, element.scroll_left);

        this.$router.push(element.route_full_path); // + "?session-id="

        if (element.event == "focus") this.setFocus(element.xpath);
        if (element.event == "click") this.simulateClick(element.xpath);

        this.cursorPosition.x = element.cursor_position_x;
        this.cursorPosition.y = element.cursor_position_y;

        await this.sleep(5000);
      }
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    handleEvent(event) {
      const elementInfo = this.getElementInfo(event.target);
      const eventType = event.type;
      const keyInfo = eventType === 'keydown' ? event.key : null; // Отримуємо інформацію про натиснуту клавішу

      const scrollPosition = {
        scrollTop: event.target.scrollTop || 0,   // Позиція скролу по вертикалі
        scrollLeft: event.target.scrollLeft || 0  // Позиція скролу по горизонталі
      };

      const data = {
        element: elementInfo,
        event: eventType,
        route_full_path: this.$route.fullPath,
        user_token: localStorage.token,
        key: keyInfo,
        user_id: 123,
        cursorPosition: {
          x: event.clientX || null,
          y: event.clientY || null
        },
        windowSize: {
          width: window.innerWidth,  // Ширина вікна
          height: window.innerHeight // Висота вікна
        },
        scrollPosition: scrollPosition
      };

      if (eventType == "scroll") {
        this.scrollData = data;
      } else {
        console.log("WS");
        console.log(data);

        if (this.scrollData) {
          socketService.emit('elementInteraction', this.scrollData);
          this.scrollData = {};
        }

        socketService.emit('elementInteraction', data);
      }

    },
    getElementInfo(element) {
      return {
        tag: element.tagName,
        id: element.id || null,
        classList: [...element.classList].join(' ') || null,
        attributes: this.getElementAttributes(element),
        text: element.innerText || null,
        xpath: this.getXPath(element)
      };
    },
    getElementAttributes(element) {
      return [...element.attributes].reduce((acc, attr) => {
        acc[attr.name] = attr.value;
        return acc;
      }, {});
    },
    getXPath(element) {
      if (element.id !== '') {
        return `//*[@id="${element.id}"]`;
      }
      if (element === document.body) {
        return `/html/body`;
      }
      let ix = 0;
      const siblings = element.parentNode.childNodes;
      for (let i = 0; i < siblings.length; i++) {
        const sibling = siblings[i];
        if (sibling === element) {
          return `${this.getXPath(element.parentNode)}/${element.tagName.toLowerCase()}[${ix + 1}]`;
        }
        if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
          ix++;
        }
      }
    },

    addLetterToInputByXPath(xpath, key) {
      const result = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
      const inputElement = result.singleNodeValue;

      if (inputElement) {
        const currentValue = inputElement.value;
        inputElement.value = currentValue + key;

        const event = new Event('input', { bubbles: true });
        inputElement.dispatchEvent(event);
      } else {
        console.log("Element not found");
      }
    },

    getElementByXPath(xpath) {
      return document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    },

    setFocus(xpath) {
      const result = document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
      const inputElement = result.singleNodeValue;

      if (inputElement) {
        inputElement.focus();
      } else {
        console.log("Element not found");
      }
    },
    highlightElement(xpath) {
      const element = this.getElementByXPath(xpath);
      if (element) {
        element.style.outline = "2px solid red";  // Додавання червоної рамки до елемента
      } else {
        console.error('Element not found for XPath:', xpath);
      }
    },

    applyScrollToElement(xpath, scrollTop, scrollLeft) {
      const element = this.getElementByXPath(xpath);
      if (element) {
        // Встановлюємо позицію скролу для елемента
        element.scrollTop = scrollTop;
        element.scrollLeft = scrollLeft;
      } else {
        console.error('Element not found for XPath:', xpath);
      }
    },

    simulateClick(xpath) {
      const element = this.getElementByXPath(xpath);

      if (element) {
        const mouseDownEvent = new MouseEvent('mousedown', {
          bubbles: true,
          cancelable: true,
          view: window
        });

        const mouseUpEvent = new MouseEvent('mouseup', {
          bubbles: true,
          cancelable: true,
          view: window
        });

        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        });

        element.dispatchEvent(mouseDownEvent);
        element.dispatchEvent(mouseUpEvent);
        element.dispatchEvent(clickEvent);

        console.log("Click simulated for element:", element);

        if (element.__vue__) {
          const vueInstance = element.__vue__;

          if (vueInstance && typeof vueInstance.$emit === 'function') {
            vueInstance.$emit('click');
            console.log("Vue click simulated for element:", element);
          } else {
            console.warn("Vue instance has no click handler:", vueInstance);
          }
        } else {
          console.error('Element not found or is not Vue component for XPath:', xpath);
        }

      } else {
        console.error('Element not found for XPath:', xpath);
      }
    },

    windowResize(width, height) {
      const element = document.querySelector('#app');
      if (element) {
        element.style.width = `${width}px`;
        element.style.height = `${height}px`;
      }
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('vue-multiselect/dist/vue-multiselect.css');

* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.app {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100vh;
}

.app-menu {
  flex: 0 0 auto;
}

.app-body {
  flex: 1 1 auto;
  /* overflow-y: auto; */
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

#record-controller {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

#record-controller .wrap {
  display: flex;
  justify-content: space-around;
  width: 200px;
}

#record-controller .wrap>div {
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 16px;
  text-align: center;
}

#record-controller .wrap>div:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

#record-controller .wrap>div:active {
  transform: scale(0.9);
}

#custom-cursor {
  width: 20px;
  height: 20px;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  /* Щоб не перехоплювати події миші */
  z-index: 9999;
  /* Курсор завжди поверх інших елементів */
}
</style>
